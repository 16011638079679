import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Approvals from '../../components/Approvals';
import { ProjectType } from '../../constants/Project';
import { classes } from '../../pages/dedocoDocument/DedocoDocument.styles';
import {
  selectDocument,
  selectForm,
  selectProcess,
  selectResultType,
  selectSubmission,
} from '../../redux/features/document/documentSlice';
import { getApprovalColor, getSignaturesColor, getSortedColor } from '../../utils/getRandomColor';
import DocumentHashHistory from '../DocumentHashHistory';
import DownloadCertificateButton from '../DownloadCertificateButton';
import Remarks from '../Remarks';
import Signatories from '../Signatories';
import { Root } from './VerifyCertification.styles';

const VerifyCertification = () => {
  const resultType = useSelector(selectResultType);

  // dSign
  const docInfo = useSelector(selectDocument);
  const process = useSelector(selectProcess);
  // dForm
  const form = useSelector(selectForm);
  const submission = useSelector(selectSubmission);

  const router = useHistory();
  if (!process?.id && !submission?.id) {
    router.push('/');
  }

  const [approvals, setApprovals] = useState([]);
  const [signerList, setSignerList] = useState([]);
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    if (!process?.id) return;

    const { approvers, signers } = process;

    if (approvers?.length) {
      const newApprovals = approvers.map((approver, index) => {
        return { ...approver, colorLabel: getApprovalColor(index) };
      });
      setApprovals(newApprovals);
    }

    if (signers?.length) {
      const newSigners = signers.map((item, index) => ({
        ...item,
        colorLabel: getSignaturesColor(index),
      }));
      setSignerList(newSigners);
    }
  }, [process]);

  useEffect(() => {
    if (!submission?.id) return;

    const { formFiller, content } = submission;
    const { digiSignatures, esignatures } = content;

    // convert to dSign-like object
    const signer = {
      signer_name: formFiller.name,
      signer_email: formFiller.email,
      digi_signatures: digiSignatures.map((s) => ({
        ...s,
        signature: s.data,
        ip_address: formFiller.ip,
        type: 'ndi',
      })),
      esignatures: esignatures.map((s) => ({
        ...s,
        signature: s.data,
        ip_address: formFiller.ip,
      })),
      colorLabel: getSignaturesColor(0),
    };

    setSignerList([signer]);
  }, [submission]);
  useEffect(() => {
    if (process?.history?.length && (signerList.length || approvals.length)) {
      const newHistory = process.history.map((itemHistory, index) => {
        const approvalColor = approvals?.find((item) => item.approver_id === itemHistory.actor.id);
        const signerColor = signerList?.find((item) => item.signer_id === itemHistory.actor.id);
        return {
          ...itemHistory,
          colorLabel: signerColor
            ? signerColor.colorLabel
            : approvalColor
            ? approvalColor.colorLabel
            : getSortedColor(index),
        };
      });
      setHistoryList(newHistory);
    }
  }, [signerList, approvals]);

  return (
    <Root>
      <Typography variant="headline_large" className={classes.title}>
        {resultType && resultType === ProjectType.Form ? form.name || '' : docInfo.name || ''}
      </Typography>

      <Typography variant="body_large" className={classes.description}>
        View and download a copy of the signing certificate for safe keeping.
      </Typography>

      {process?.id && <DownloadCertificateButton />}

      {/* Document Hash History */}
      {!!process?.id && (
        <>
          <div className={classes.titleTable}>
            <KeyboardArrowDownIcon style={{ color: '#8E8E8E' }} />
            <Typography variant="title_large" className={classes.title}>
              Document Hash History
            </Typography>
          </div>
          <DocumentHashHistory history={process?.history} />
        </>
      )}

      {/* Remarks */}
      {!!historyList?.length && (
        <>
          <div className={classes.titleTable}>
            <KeyboardArrowDownIcon style={{ color: '#8E8E8E' }} />
            <Typography variant="title_large" className={classes.title}>
              Remarks
            </Typography>
          </div>

          <Remarks history={historyList} />
        </>
      )}

      {/* Signatories */}
      {!!signerList?.length && (
        <>
          <div className={classes.titleTable}>
            <KeyboardArrowDownIcon style={{ color: '#8E8E8E' }} />
            <Typography variant="title_large" className={classes.title}>
              Signatories
            </Typography>
          </div>
          <Signatories signers={signerList} />
        </>
      )}

      {/* Approvals */}
      {!!approvals?.length && (
        <>
          <div className={classes.titleTable}>
            <KeyboardArrowDownIcon style={{ color: '#8E8E8E' }} />
            <Typography variant="title_large" className={classes.title}>
              Approvals
            </Typography>
          </div>

          <Approvals approvers={approvals} />
        </>
      )}
    </Root>
  );
};

export default VerifyCertification;
