export const PrintIcon = ({ color = '#FFFFFF', ...props }) => {
  return (
    <svg
      width="14"
      color={color}
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.66683 3.33333V1.33333H4.3335V3.33333H3.00016V0.666667C3.00016 0.477778 3.06394 0.319333 3.1915 0.191333C3.3195 0.0637777 3.47794 0 3.66683 0H10.3335C10.5224 0 10.6806 0.0637777 10.8082 0.191333C10.9362 0.319333 11.0002 0.477778 11.0002 0.666667V3.33333H9.66683ZM1.66683 4.66667H12.3335H3.00016H1.66683ZM11.0002 6.33333C11.1891 6.33333 11.3473 6.26933 11.4748 6.14133C11.6028 6.01378 11.6668 5.85556 11.6668 5.66667C11.6668 5.47778 11.6028 5.31933 11.4748 5.19133C11.3473 5.06378 11.1891 5 11.0002 5C10.8113 5 10.6531 5.06378 10.5255 5.19133C10.3975 5.31933 10.3335 5.47778 10.3335 5.66667C10.3335 5.85556 10.3975 6.01378 10.5255 6.14133C10.6531 6.26933 10.8113 6.33333 11.0002 6.33333ZM4.3335 10.6667H9.66683V8H4.3335V10.6667ZM4.3335 12C3.96683 12 3.65305 11.8696 3.39216 11.6087C3.13083 11.3473 3.00016 11.0333 3.00016 10.6667V9.33333H1.00016C0.811274 9.33333 0.653052 9.26933 0.525496 9.14133C0.397496 9.01378 0.333496 8.85556 0.333496 8.66667V5.33333C0.333496 4.76667 0.527941 4.29178 0.916829 3.90867C1.30572 3.52511 1.77794 3.33333 2.3335 3.33333H11.6668C12.2335 3.33333 12.7086 3.52511 13.0922 3.90867C13.4753 4.29178 13.6668 4.76667 13.6668 5.33333V8.66667C13.6668 8.85556 13.6028 9.01378 13.4748 9.14133C13.3473 9.26933 13.1891 9.33333 13.0002 9.33333H11.0002V10.6667C11.0002 11.0333 10.8697 11.3473 10.6088 11.6087C10.3475 11.8696 10.0335 12 9.66683 12H4.3335ZM12.3335 8V5.33333C12.3335 5.14444 12.2695 4.986 12.1415 4.858C12.0139 4.73044 11.8557 4.66667 11.6668 4.66667H2.3335C2.14461 4.66667 1.98616 4.73044 1.85816 4.858C1.73061 4.986 1.66683 5.14444 1.66683 5.33333V8H3.00016V6.66667H11.0002V8H12.3335Z"
        fill="currentColor"
      />
    </svg>
  );
};
