import Axios from 'axios';

import { process_env } from '../../utils/envData';

const dedocoApiUrl = process_env('REACT_APP_DEDOCO_API');

const dedocoApi = Axios.create({ baseURL: dedocoApiUrl, timeout: 50000 });

const dCertApiUrl = process_env('REACT_APP_DCERT_API');

const dCertApi = Axios.create({ baseURL: dCertApiUrl, timeout: 50000 });

export { dedocoApi, dCertApi };
