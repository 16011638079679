import { Drawer, styled } from '@mui/material';

const PREFIX = 'SideMenu';

export const classes = {
  content: `${PREFIX}-content`,
  closeIcon: `${PREFIX}-close-icon`,
  getHelpButton: `${PREFIX}-get-help`,
  groupButton: `${PREFIX}-group-button`,
  homeButton: `${PREFIX}-home-button`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .MuiButton-root`]: {
    padding: 0,
    width: '100%',
    height: '32px',
    color: theme.palette.background.main,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    minWidth: '32px',
    borderRadius: '8px',
  },

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const Sidebar = styled(Drawer)(({ theme }) => ({
  [`& .MuiPaper-root`]: {
    backgroundColor: theme.palette.background.base,
    borderRadius: '20px 0px 0px 20px',
  },
  [`& .${classes.content}`]: {
    width: '334px',
    height: '100%',
    padding: theme.spacing(10, 6),
  },
  [`& .${classes.closeIcon}`]: {
    color: theme.palette.background.main,
  },
  [`& .${classes.getHelpButton}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    color: theme.palette.background.main,
  },
  [`& .${classes.groupButton}`]: {
    gap: theme.spacing(3),
    [`& .MuiButton-root`]: {
      justifyContent: 'flex-start',
    },
  },
  [`& .${classes.homeButton}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    color: theme.palette.background.main,
    marginBottom: theme.spacing(3),
  },
}));
