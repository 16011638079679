import { DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { classes, Root } from './InvalidDocument.style';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

function InvalidDocument({ openModal, onClose }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const rerouteDedocoForm = async (_e) => {
    window.location = 'https://www.dedoco.com/forms/30-day-trial';
  };

  return (
    <Root open={open} onClose={handleClose} className="shafa" fullScreen={fullScreen}>
      <Grid container direction="row" alignItems="flex-start" className={classes.gridContainer}>
        <Grid className={classes.content} direction="column">
          <div className={classes.topGrayBar}></div>
          <DialogTitle>Document cannot be verified</DialogTitle>
          <DialogContent>
            <Typography className={classes.subtitle} variant="body_medium">
              Oops, we're having trouble verifying your document. Make sure your document.
            </Typography>
            <ul className={classes.contentList}>
              <li className={classes.contentListItem}>
                <span className={classes.textHighlight}>
                  Has been processed and registred on the ledger through Dedoco platform.
                </span>
                &nbsp;At the moment, we can only accept documents processed and registered from 6
                Feb 2021.
              </li>
              <li className={classes.contentListItem}>
                <span className={classes.textHighlight}>Has not been tampered with.</span>&nbsp;if
                the document hase been downloaded upon completion of signing, any changes, however
                little, to the document will deem the verifying process invalid.
              </li>
            </ul>
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Link className={classes.createButton} onClick={rerouteDedocoForm}>
              Register with Dedoco
            </Link>
            <Button className={classes.uploadButton} onClick={handleClose}>
              Upload another file
            </Button>
          </DialogActions>
        </Grid>
        <Button className={classes.closeButton} onClick={handleClose}>
          X
        </Button>
      </Grid>
    </Root>
  );
}

export default InvalidDocument;
