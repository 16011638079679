import { Typography } from '@mui/material';
import { classes, Root } from './Card.styles';

const Card = ({ icon, title, description }) => {
  return (
    <Root key={title}>
      <img src={icon} alt="icon" />
      <Typography variant="title_large" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body_medium" className={classes.description}>
        {description}
      </Typography>
    </Root>
  );
};

export default Card;
