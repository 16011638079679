export const getSortedColor = (index) => {
  const colors = [
    '#FECC4A',
    '#767685',
    '#FFB40C',
    '#33C491',
    '#FD6565',
    '#2AA9F0',
    '#2A56F0',
    '#EB46EE',
    '#A21CE7',
    '#B81C3B',
    '#734516',
    '#447817',
    '#C1ADFF',
    '#6333FF',
    '#FFA366',
    '#FF6600',
    '#B94A00',
    '#FD6565',
    '#FF6600',
  ];
  return colors[index];
}

export const getSignaturesColor = (index) => {
  const colors = [
    '#6333FF',
    '#FF6600',
    '#C1ADFF',
    '#FFA366',
    '#B94A00',
    '#FD6565',
    '#2AA9F0',
    '#2A56F0',
    '#EB46EE',
    '#A21CE7',
    '#B81C3B',
    '#734516',
    '#447817'
  ];
  return colors[index];
}

export const getApprovalColor = (index) => {
  const colors = [
    '#904EC5',
    '#FD6565',
    '#2AA9F0',
    '#2A56F0',
    '#EB46EE',
    '#A21CE7',
    '#C1ADFF',
    '#6333FF',
    '#FFA366',
    '#FF6600',
    '#B94A00',
    '#FD6565',
    '#2AA9F0',
    '#2A56F0',
    '#EB46EE',
    '#A21CE7',
    '#B81C3B',
    '#734516',
    '#447817'
  ];
  return colors[index];
}

export const getObserverColor = (index) => {
  const colors = [
    '#33C491',
    '#FFA366',
    '#B81C3B',
    '#FD6565',
    '#2AA9F0',
    '#2A56F0',
    '#EB46EE',
    '#A21CE7',
    '#C1ADFF',
    '#6333FF',
    '#FF6600',
    '#B94A00',
    '#FD6565',
    '#A21CE7',
    '#734516',
    '#447817'
  ];
  return colors[index];
}
