import { AppBar, Button, Stack, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { dedocoTheme } from '../../themes/dedocoTheme';
import { CloseIconComponent } from '../icons/CloseIcon';
import { HelpIcon } from '../icons/HelpIcon';
import { HomeIcon } from '../icons/HomeIcon';

export const DCertSidebar = ({ children, ...props }) => {
  const closeSidebarHandler = () => {
    document.getElementById('sidebar')?.classList?.remove('show');
  };

  const location = useLocation();
  const isBuilderApp = location.search.replace(/(.*)=/, '') === 'builder';
  const handleBackSite = () => {
    isBuilderApp
      ? window.location.replace('https://app.dedoco.com')
      : window.location.replace('https://dedoco.com');
  };

  return (
    <AppBar
      {...props}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        boxShadow: 'unset',
        backgroundColor: 'rgba(34, 34, 34, 0.1)',
        backdropFilter: 'blur(10px)',
        height: '100%',
      }}
      id="sidebar"
    >
      <Toolbar
        variant="dense"
        sx={{
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          minWidth: '20rem',
          backgroundColor: dedocoTheme.palette.background.base,
          borderRadius: '1.25rem 0px 0px 1.25rem',
        }}
      >
        <Stack
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          width="100%"
          boxSizing="border-box"
          px={3}
          py={5}
        >
          <Stack width="100%" mb={4} justifyContent="flex-end">
            <CloseIconComponent
              onClick={closeSidebarHandler}
              style={{
                cursor: 'pointer',
              }}
            />
          </Stack>
          <Stack width="100%">
            {children}
            <Button
              onClick={handleBackSite}
              startIcon={<HomeIcon />}
              variant="label_large"
              sx={{
                justifyContent: 'flex-start',
                mt: 3,
                width: '100%',
                height: dedocoTheme.spacing(11),
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                borderRadius: dedocoTheme.spacing(2),
                ['&:hover']: {
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                },
              }}
            >
              Return to Dedoco Homepage
            </Button>

            <Button
              onClick={() => window.open('https://www.dedoco.com/faq', '_blank', 'noopener')}
              startIcon={<HelpIcon />}
              variant="label_large"
              sx={{
                mt: 3,
                width: '100%',
                height: dedocoTheme.spacing(11),
                justifyContent: 'flex-start',
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                borderRadius: dedocoTheme.spacing(2),
                ['&:hover']: {
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                },
              }}
            >
              Get Help
            </Button>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
