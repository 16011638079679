import { sha3_256 } from 'js-sha3';
/**
 * get the hash of a selected file
 * can be used in conjunction with antd Upload
 */
export async function getFileHash(_file) {
  if (!_file) return '';
  const data = await readFileBufferAsync(_file);
  const hash = sha3_256.create();
  hash.update(data);
  _file['hash'] = hash.hex();
  return hash.hex();
}

export function readFileBufferAsync(_file) {
  if (!_file) return Promise.reject();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;

    reader.readAsArrayBuffer(_file);
  });
}
