import { styled, Dialog } from '@mui/material';

const PREFIX = 'multiDoc';

export const classes = {
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  textHighlight: `${PREFIX}-text-highlight`,
  tableContainer: `${PREFIX}-table-container`,
  headerText: `${PREFIX}-header-text`,
  topGrayBar: `${PREFIX}-top-gray-bar`,
  tableHeadColumn: `${PREFIX}-column-table-head`,
  tableHeadColumnSlim: `${PREFIX}-column-table-head-slim`,
  leftMargin16: `${PREFIX}-left-margin-15`,
  creationDate: `${PREFIX}-creation-date`,
  columnDocInfo: `${PREFIX}-column-doc-info`,
  docInfo: `${PREFIX}-doc-name-hash`,
  columnLastUpdatedInfo: `${PREFIX}-column-last-updated-info`,
  columnOwnerInfo: `${PREFIX}-column-owner-info`,
  radioButton: `${PREFIX}-radio-button`,
  radioButtonMobile: `${PREFIX}-radio-button-mobile`,
  closeButton: `${PREFIX}-close-button`,
  selectedDoc: `${PREFIX}-selected-doc`,
  selectButton: `${PREFIX}-select-button`,
  hiddenOnMobile: `${PREFIX}-hidden-on-mobile`,
};

export const Root = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    top: 'auto',
  },

  [`& .${classes.topGrayBar}`]: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginInline: 'auto',
      marginBlock: theme.spacing(4),
      height: '5px',
      width: '30%',
      background: theme.palette.lightGrey.light,
      borderRadius: '3px',
    },
  },

  [`& .MuiGrid-container`]: {
    backgroundColor: 'transparent !important',
  },

  [`& .MuiPaper-root`]: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    maxWidth: '878px',
  },

  [`& .${classes.title}`]: {
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(4),
    fontWeight: 'bold',
  },

  [`& .${classes.content}`]: {
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(0.1),
    borderRadius: '8px',
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },

    [`& .css-1nrlq1o-MuiFormControl-root`]: {
      width: '100%',
    },

    [`& .${classes.subtitle}`]: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },

    [`& .${classes.headerText}`]: {
      color: theme.palette.text.secondary,
    },

    [`& .${classes.leftMargin16}`]: {
      marginLeft: theme.spacing(4),
    },

    [`& .${classes.tableContainer}`]: {
      marginTop: theme.spacing(2),
      maxHeight: '350px',
    },

    [`& .${classes.tableHeadColumn}`]: {
      backgroundColor: theme.palette.supporting.main,
      minWidth: '173px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '130px',
      },
    },

    [`& .${classes.tableHeadColumnSlim}`]: {
      backgroundColor: theme.palette.supporting.main,
      minWidth: '160pxpx',
      [theme.breakpoints.down('sm')]: {
        minWidth: '130px',
      },
    },

    [`& .${classes.hiddenOnMobile}`]: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    [`& .${classes.columnDocInfo}`]: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },

    [`& .${classes.docInfo}`]: {
      display: 'flex',
      flexDirection: 'column',
    },

    [`& .${classes.creationDate}`]: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },

    [`& .${classes.columnLastUpdatedInfo}`]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    [`& .${classes.radioButton}`]: {
      marginLeft: 'auto',
      marginRight: theme.spacing(3),
      '& svg': {
        width: '0.9em',
        height: '0.9em',
      },
    },

    [`& .${classes.radioButtonMobile}`]: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        marginLeft: 'auto',
        '& svg': {
          width: '0.9em',
          height: '0.9em',
        },
      },
    },

    [`& .MuiDialogActions-root`]: {
      display: 'flex',
      padding: theme.spacing(5, 0, 5, 0),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    [`& .MuiDialogContent-root`]: {
      padding: theme.spacing(0),
    },

    [`& .${classes.textHighlight}`]: {
      color: `${theme.palette.text.black}`,
      fontWeight: 'bold',
    },

    [`& .${classes.selectedDoc}`]: {
      margin: theme.spacing(0, 0, 0, 4),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(3, 0, 3, 0),
      },
    },

    [`& .${classes.selectButton}`]: {
      fontWeight: 'bold',
      fontSize: '13px',
      background: theme.palette.primary.main,
      color: theme.palette.supporting.extraLight,
      margin: theme.spacing(0, 4, 0, 0),
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        margin: theme.spacing(0),
      },
    },
  },

  [`& .${classes.closeButton}`]: {
    background: theme.palette.supporting.dark,
    borderRadius: '50%',
    minWidth: '40px',
    height: '40px',
    marginLeft: '3px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
