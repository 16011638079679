import './App.css';

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Opencert from './components/Opencert';

import ScrollToTop from './utils/ScrollToTop';
import DedocoVerify from './pages/dedocoVerify';
import DedocoDocument from './pages/dedocoDocument';
import DownloadCertificate from './components/DownloadCertificate';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={DedocoVerify} />
        <Route exact path="/document" component={DedocoDocument} />
        <Route path="/download-certificate" component={DownloadCertificate} />
        <Route exact path="/opencert/:certificateId?" component={Opencert} />
      </Switch>
    </div>
  );
}

export default App;
