import { classes, Root } from './Layout.styles';
import SideMenu from '../components/SideMenu';
import helpIcon from '../assets/icons/help.svg';
import newDedocoLogo from '../assets/icons/new-dedoco-logo.svg';
import verifyIcon from '../assets/icons/verify.svg';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';

import { Box, useMediaQuery } from '@mui/material';
import { dedocoTheme } from '../themes/dedocoTheme';
import { BurgerMenuIcon } from '../components/icons/BurgerMenuIcon';

const Layout = ({ children, opencerts }) => {
  const openHelp = () => {
    window.open('https://www.dedoco.com/faq', '_blank', 'noopener');
  };

  const location = useLocation();
  const isDownloadCertification = location.pathname.replace('/', '') === 'download-certificate';
  const isMobile = useMediaQuery(dedocoTheme.breakpoints.down('md'));

  const toggleSidebarHandler = () => {
    const sidebar = document.getElementById('sidebar');
    sidebar.classList.add('show');
  };

  return (
    <Root>
      <nav className={classes.navbar}>
        <div className={classes.navbarIcons}>
          <img className={classes.logo} alt="new-dedoco-logo" src={newDedocoLogo} />
          <div className={classes.iconWrapper}>
            <img className={classes.signIcon} alt="verify" src={verifyIcon} />
          </div>
        </div>
        <div className={classes.innerIconWrapper} onClick={openHelp}>
          <img className={classes.helpIcon} src={helpIcon} alt="help" />
        </div>
        {isMobile ? (
          !opencerts ? (
            <SideMenu />
          ) : (
            <Box
              onClick={toggleSidebarHandler}
              sx={{
                width: dedocoTheme.spacing(11),
                height: dedocoTheme.spacing(11),
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                borderRadius: dedocoTheme.spacing(2),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BurgerMenuIcon width="1rem" onClick={toggleSidebarHandler} />
            </Box>
          )
        ) : (
          <></>
        )}
      </nav>
      <main className={opencerts ? classes.opencertsMain : classes.main}>
        {!isDownloadCertification && !opencerts && (
          <div className={classes.header}>
            <Header opencerts />
          </div>
        )}
        <div className={opencerts ? classes.opencertsContent : classes.content}>{children}</div>
      </main>
    </Root>
  );
};

export default Layout;
