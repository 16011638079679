import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  LabelStyled,
  StyledTableCell,
  TableStyled,
  TableMobile,
  TableChildren,
  DateRowStyled,
  DateCellStyled,
  DateValueCellStyled,
  HashRowStyled,
  LabelMobileStyled
} from './Approvals.styles';
import TableBody from '@mui/material/TableBody';

const Approvals = ({approvers}) => {
  return (
    <>
      <TableStyled sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Contact</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {approvers?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell>
                <LabelStyled
                  colorLabel={row.colorLabel}
                  colorText="#FFFFFF">
                  {row.approver_name}
                </LabelStyled>
                {row.ip_address}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.approver_email}
              </StyledTableCell>
              <StyledTableCell>
                {row.has_approved && 'Approved'}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableStyled>

      <TableMobile>
        {approvers?.map((row, index) => (
          <TableChildren key={index}>
            <DateRowStyled>
              <DateCellStyled>Name</DateCellStyled>
              <DateValueCellStyled>
                <LabelMobileStyled
                  colorLabel={row.colorLabel}
                  colorText="#FFFFFF">
                  {row.approver_name}
                </LabelMobileStyled>
                {row.ip_address}
              </DateValueCellStyled>
            </DateRowStyled>

            <HashRowStyled>
              <DateCellStyled>Contact</DateCellStyled>
              <DateCellStyled>{row.approver_email}</DateCellStyled>
            </HashRowStyled>

            <HashRowStyled>
              <DateCellStyled>Status</DateCellStyled>
              <DateCellStyled>{row.has_approved && 'Approved'}</DateCellStyled>
            </HashRowStyled>
          </TableChildren>
        ))}
      </TableMobile>
    </>
  )
}

export default Approvals;
