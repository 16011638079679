import { Button, Chip, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
import CloseIcon from '../../assets/icons/close-icon.svg';
import EyeIcon from '../../assets/icons/eye.svg';
import MinusIcon from '../../assets/icons/minus-icon.svg';
import PlusIcon from '../../assets/icons/plus-icon.svg';
import QuestionIcon from '../../assets/icons/question.svg';
import ViewDocument from '../../assets/icons/view-document.svg';
import { ProjectType } from '../../constants/Project';
import {
  selectDocument,
  selectForm,
  selectHash,
  selectProcess,
  selectResultType,
  selectSubmission,
} from '../../redux/features/document/documentSlice';
import { isoToLocaleString } from '../../utils/dateParser';
import { getApprovalColor, getObserverColor, getSignaturesColor } from '../../utils/getRandomColor';
import {
  classes,
  InfoCardStyled,
  ModalStyled,
  PdfDocumentStyled,
  Root,
  StatusCardStyled,
  TitleModal,
  ViewDocumentStyled,
} from './VerifyInfoCard.styles';

const InfoCard = ({
  label,
  icon,
  content,
  colorText,
  backgroundCard,
  status,
  userList,
  userKey,
  userName,
}) => {
  return (
    <InfoCardStyled>
      <div className={classes.TitleCard}>
        <Typography variant="title_small" className={classes.TextCard}>
          {label}
        </Typography>
        {icon && <img src={icon} alt="icon" />}
      </div>

      {content && <Typography variant="body_medium_gray">{content}</Typography>}
      <Stack direction={'row'} spacing={1} sx={{ overflow: 'scroll', width: '100%' }}>
        {!!userList?.length &&
          userList.map((u) => (
            <Chip
              label={u[userName]}
              key={u[userKey]}
              sx={{
                backgroundColor: u.colorLabel,
                color: colorText,
                fontWeight: 500,
              }}
            />
          ))}
      </Stack>
      {status && (
        <StatusCardStyled color={colorText} background={backgroundCard} variant="label_small">
          {status}
        </StatusCardStyled>
      )}
    </InfoCardStyled>
  );
};

const VerifyInfoCard = () => {
  const resultType = useSelector(selectResultType);
  const hash = useSelector(selectHash);

  const docInfo = useSelector(selectDocument);
  const process = useSelector(selectProcess);
  const form = useSelector(selectForm);
  const submission = useSelector(selectSubmission);

  const [isOpen, setIsOpen] = useState(false);
  const base64 = useSelector((state) => state.document.base64);

  const [totalPages, setTotalPages] = useState(0);
  const [zoom, setZoom] = useState(1.3); // 1.3 is 100%
  const [currentPage, setCurrentPage] = useState(1);
  const [allPageNumbers, setAllPageNumbers] = useState([]);
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const onDocumentLoadSuccess = ({ numPages }) => {
    for (let p = 1; p < numPages + 1; p++) {
      allPageNumbers.push(p);
    }
    setAllPageNumbers(allPageNumbers);
    setTotalPages(numPages);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const zoomIn = () => {
    if (zoom >= 1.4) setZoom(1.5);
    else setZoom(zoom + 0.1);
  };
  const zoomOut = () => {
    if (zoom <= 1.1) setZoom(1);
    else setZoom(zoom - 0.1);
  };

  const handleScroll = (el) => {
    el.nativeEvent.target.childNodes[0].childNodes.forEach((item, index) => {
      if (el.target.scrollTop > item.offsetTop) {
        setCurrentPage(index + 1);
      }
    });
  };

  const [approvals, setApprovals] = useState([]);
  const [signerList, setSignerList] = useState([]);
  const [observerList, setObserverList] = useState([]);

  useEffect(() => {
    if (!process?.id) return;

    const { approvers, signers, observers } = process;

    if (approvers?.length) {
      const newApprovals = approvers.map((approver, index) => {
        return { ...approver, colorLabel: getApprovalColor(index) };
      });
      setApprovals(newApprovals);
    }

    if (signers?.length) {
      const newSigners = signers.map((item, index) => ({
        ...item,
        colorLabel: getSignaturesColor(index),
      }));
      setSignerList(newSigners);
    }

    if (observers?.length) {
      const newObservers = observers.map((item, index) => ({
        ...item,
        colorLabel: getObserverColor(index),
      }));
      setObserverList(newObservers);
    }
  }, [process]);

  const [docName, setDocName] = useState('');
  const [creationDate, setCreationDate] = useState(null);
  useEffect(() => {
    if (!docInfo?.id && !submission?.id && !form?.id) return;
    if (!resultType) return;

    switch (resultType) {
      case ProjectType.Form:
        setDocName(form.name);
        setCreationDate(submission.createdAt);
        break;
      case ProjectType.Sign:
        setDocName(docInfo.name);
        setCreationDate(docInfo.date_created);
      default:
    }
  }, [docInfo, form, submission]);

  return (
    <Root>
      <Typography variant="title_small" className={classes.HeadingSidebar}>
        About the {resultType && resultType === ProjectType.Form ? 'Form' : 'Document'}
      </Typography>

      <div className={classes.DividerDocument}></div>

      <ViewDocumentStyled>
        <img className={classes.ImageDocument} src={ViewDocument} alt="view-document" />
        <Button className={classes.ViewDocumentButton} onClick={handleOpen}>
          <img className={classes.EyeIconDocument} src={EyeIcon} alt="eye" />
          View document
        </Button>
      </ViewDocumentStyled>

      <InfoCard label="Name" content={docName} />

      <InfoCard label="Document Hash" content={hash || '-'} icon={QuestionIcon} />

      <InfoCard label="Creation Date" content={isoToLocaleString(creationDate)} />

      <InfoCard label="Blockchain Contract" content="-" icon={QuestionIcon} />

      {process?.status && (
        <InfoCard
          label="Status"
          status={process.status}
          colorText="#33C491"
          backgroundCard="rgba(51, 196, 145, 0.16)"
        />
      )}

      {!!approvals.length && (
        <InfoCard
          label="Approvers"
          userList={approvals}
          userKey="approver_id"
          userName="approver_name"
          colorText="#FFFFFF"
          backgroundCard="#904EC5"
        />
      )}

      {!!signerList.length && (
        <InfoCard
          label="Signers"
          userList={signerList}
          userKey="signer_id"
          userName="signer_name"
          colorText="#FFFFFF"
          backgroundCard="#6333FF"
        />
      )}

      {!!observerList.length && (
        <InfoCard
          label="Observers"
          userList={observerList}
          userKey="id"
          userName="name"
          colorText="#FFFFFF"
          backgroundCard="#1EB564"
        />
      )}

      {/* Modal View Document */}
      <ModalStyled open={isOpen}>
        <div className={classes.CloseModal} onClick={handleClose}>
          <img src={CloseIcon} alt="close-icon" />
        </div>
        <TitleModal variant="headline_small" className={classes.HeadingSidebar}>
          View {resultType && resultType === ProjectType.Form ? 'submission' : 'document'}
        </TitleModal>
        <div className={classes.ContentModal}>
          <div className={classes.HeadingPdf}>
            <div className={classes.InfoPdf}>
              <Typography variant="title_medium" className={classes.NamePdf}>
                {docInfo.name || ''}
              </Typography>
              <Typography variant="title_medium" className={classes.PagePdf}>
                Page {currentPage} of {totalPages}
              </Typography>
            </div>

            <div className={classes.ZoomPdf}>
              <div className={`${classes.ToggleZoomPdf} ${classes.ZoomOut}`} onClick={zoomOut}>
                <img src={MinusIcon} alt="minus-icon" />
              </div>
              <Typography variant="title_medium" className={classes.PercentViewPdf}>
                {parseInt(Math.round((zoom - 0.3) * 100))}%
              </Typography>
              <div className={`${classes.ToggleZoomPdf} ${classes.ZoomIn}`} onClick={zoomIn}>
                <img src={PlusIcon} alt="plus-icon" />
              </div>
            </div>
          </div>
          <PdfDocumentStyled id="scrollArea" onScroll={handleScroll}>
            <Document
              file={base64}
              id="react-pdf-document"
              onLoadError={console.error}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {allPageNumbers.map((item, index) => (
                <Page key={`page-${index}`} pageNumber={item} scale={zoom}></Page>
              ))}
            </Document>
          </PdfDocumentStyled>
        </div>
      </ModalStyled>
    </Root>
  );
};

export default VerifyInfoCard;
