import { styled, Dialog } from '@mui/material';

const PREFIX = 'InvalidDoc';

export const classes = {
  content: `${PREFIX}-content`,
  subtitle: `${PREFIX}-subtitle`,
  contentList: `${PREFIX}-content-list`,
  textHighlight: `${PREFIX}-text-highlight`,
  topGrayBar: `${PREFIX}-top-gray-bar`,
  contentListItem: `${PREFIX}-content-list-item`,
  closeButton: `${PREFIX}-close-button`,
  gridContainer: `${PREFIX}-grid-container`,
  createButton: `${PREFIX}-create-button`,
  uploadButton: `${PREFIX}-upload-button`,
};

export const Root = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    top: 'auto',
  },

  [`& .${classes.topGrayBar}`]: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginInline: 'auto',
      marginBlock: theme.spacing(4),
      height: '5px',
      width: '30%',
      background: theme.palette.lightGrey.light,
      borderRadius: '3px',
    },
  },

  [`& .MuiGrid-container`]: {
    backgroundColor: 'transparent !important',
  },

  [`& .MuiPaper-root`]: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
  },

  [`& .${classes.content}`]: {
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(0.1),
    borderRadius: '8px',
    maxWidth: '513px',
    [`& .${classes.subtitle}`]: {
      color: theme.palette.text.secondary,
    },

    [`& .MuiDialogTitle-root`]: {
      fontWeight: 'bold',
    },

    [`& .MuiDialogActions-root`]: {
      display: 'flex',
      padding: theme.spacing(5, 0, 5, 0),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },

    [`& .MuiDialogContent-root`]: {
      paddingBottom: theme.spacing(0),
    },

    [`& .${classes.contentList}`]: {
      'padding-inline-start': theme.spacing(4),
    },

    [`& .${classes.contentListItem}`]: {
      fontWeight: '400',
      fontSize: '14px',
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(2),
    },

    [`& .${classes.textHighlight}`]: {
      color: `${theme.palette.text.black}`,
      fontWeight: 'bold',
    },

    [`& .${classes.createButton}`]: {
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: 'bold',
      fontSize: '13px',
      color: theme.palette.primary.main,
      margin: theme.spacing(0, 0, 0, 4),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(3, 0, 0, 0),
      },
    },

    [`& .${classes.uploadButton}`]: {
      fontWeight: 'bold',
      fontSize: '13px',
      background: theme.palette.primary.main,
      color: theme.palette.supporting.extraLight,
      margin: theme.spacing(0, 4, 0, 0),
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        margin: theme.spacing(0),
      },
    },
  },

  [`& .${classes.closeButton}`]: {
    background: theme.palette.supporting.dark,
    borderRadius: '50%',
    minWidth: '40px',
    height: '40px',
    marginLeft: '3px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
