import { styled, Button } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

const PREFIX = 'UploadFile';

export const classes = {
  box: `${PREFIX}-box`,
  boxDescription: `${PREFIX}-boxDescription`,
  typeUpload: `${PREFIX}-typeUpload`,
  divide: `${PREFIX}-divide`,
  dividerItem: `${PREFIX}-dividerItem`,
  text: `${PREFIX}-text`,
  verifyText: `${PREFIX}-verifyText`,
  filename: `${PREFIX}-filename`,
  cancelUpload: `${PREFIX}-cancelUpload`
};

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '234px',
  backgroundColor: theme.palette.supporting.extraLight,
  borderRadius: '8px',
  padding: theme.spacing(3),
  boxSizing: 'border-box',

  [`& .${classes.box}`]: {
    margin: theme.spacing(1),
    width: '100%',
    height: '100%',
    border: `2px dashed ${theme.palette.primary.light}`,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(1),
    },
  },

  [`& .${classes.boxDescription}`]: {
    color: '#6531FF',

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

export const UploadFileStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.background.base,
  padding: theme.spacing(3, 4, 3, 4),
  margin: theme.spacing(2),
  borderRadius: '8px',
  backgroundColor: theme.palette.background.main,
  border: `1px solid ${theme.palette.supporting.main}`,

  [`& .${classes.typeUpload}`]: {
    margin: theme.spacing(2),
  },
}));

export const DividerStyled = styled('div')(({ theme }) => ({
  color: theme.palette.darkGrey.light,
  margin: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },

  [`& .${classes.dividerItem}`]: {
    width: '1px',
    height: '32px',
    backgroundColor: theme.palette.darkGrey.light,
    display: 'flex',
    margin: '0',
  },

  [`& .${classes.text}`]: {
    margin: theme.spacing(1),
  },
}));

export const ProgressBarBox = styled(LinearProgress)(({theme}) => ({
  width: '320px',
  height: '4px',
  borderRadius: '100px',
  margin: theme.spacing(6, 'auto'),

  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(4, 'auto'),
    width: '240px',
  },
}));

export const VerifyingBox = styled('div')(({theme}) => ({
  [`& .${classes.verifyText}`]: {
    color: theme.palette.background.base,
  },

  [`& .${classes.filename}`]: {
    color: theme.palette.text.secondary
  },
}));

export const CancelButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.supporting.main,
  border: '1px solid #E6DEFF',
  borderRadius: '8px',
}))
