// import KeyboardArrowDownIcon from '@mui/icons-material/ArrowDropDown';
// import MenuItem from '@mui/material/MenuItem';
// import {useState} from 'react';
import { ButtonStyled, DownloadIconStyled } from './DownloadCertificateButton.styles';
import { useHistory } from 'react-router-dom';
import DownloadIcon from '../../assets/icons/download-icon.svg';

const DownloadCertificateButton = () => {
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event) => {
    history.push('/download-certificate');
    // setAnchorEl(event.currentTarget);
  };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <>
      <ButtonStyled
        id="demo-customized-button"
        // aria-controls={open ? 'demo-customized-menu' : undefined}
        // aria-haspopup="true"
        // aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
      >
        <DownloadIconStyled src={DownloadIcon} alt="download-icon" />
        Download certificate
      </ButtonStyled>

      {/* Will be continue after finished UI certification
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          Sharing certificate only
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          Sharing certificate with embedded document
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          Coming soon
        </MenuItem>
      </StyledMenu>
      */}
    </>
  );
};

export default DownloadCertificateButton;
