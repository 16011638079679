import React, { useEffect, useState } from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import footer from '../../assets/verify/NewCertificateFooter.png';
import ApprovalIcon from '../../assets/verify/Approval.png';
import SigningBox from '../../assets/verify/NewBlankSignBox.png';
import { useSelector } from 'react-redux';
import { selectDocument, selectProcess } from '../../redux/features/document/documentSlice';
import { isoToStringWithTimezone } from '../../utils/dateParser';
import { useHistory } from 'react-router-dom';
import { getStatusTagColor } from '../../utils/colors';
import { styles } from './DownloadCertificate.styles';
import moment from 'moment';
import { ellipsis } from '../../utils/ellipsis';
import {dedocoTheme} from '../../themes/dedocoTheme';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import Layout from '../../layout';

function Header({ docInfo, processInfo }) {
  return (
    <>
      <View style={styles.header} fixed>
        <View style={styles.headerText}>
          <Text style={styles.title}>Certificate</Text>
          <Text style={styles.title}>of Completion</Text>
          <Text style={styles.text}>Dedoco signing certificate</Text>
        </View>
        <View style={styles.headerText}>
          <Text style={styles.headerContentText}>Project Name:&nbsp;{docInfo && docInfo.name}</Text>
          <Text style={styles.headerContentText}>
            Project ID:&nbsp;{processInfo && processInfo.id}
          </Text>
          <Text style={styles.headerContentText}>
            Project Creator:&nbsp;{(processInfo && processInfo.owner?.name) || ''}
          </Text>
          <Text style={styles.headerContentText}>
            Project Created:&nbsp;{isoToStringWithTimezone(processInfo && processInfo.date_created)}
          </Text>
          <Text style={styles.headerContentText}>
            Last Update:&nbsp;
            {isoToStringWithTimezone(
              processInfo &&
                processInfo.history &&
                processInfo.history[processInfo.history.length - 1].timestamp
            )}
          </Text>
          <Text style={styles.headerContentText}>
            Project Status:&nbsp;
            <ProjectStatus status={processInfo && processInfo.status} />
          </Text>
        </View>
      </View>
      <View style={styles.horizontalBarGray} />
    </>
  );
}

function Footer() {
  return (
    <View style={styles.footer} fixed>
      <Text style={styles.timestamp}>{`Information is retrieved on ${
        new Date().toString().split(/ (.+)/)[1]
      }`}</Text>

      <view style={styles.footerImageContainer}>
        <Image style={styles.footerImage} src={footer} />
      </view>
    </View>
  );
}

function SignerRow({ signer, i }) {
  const { role, name, email, ip, hasSigned, hasDeclined, type, signature, signedDate } = signer;
  return (
    <View wrap={false}>
      <View style={styles.tableBodyRow}>
        <View style={styles.tableBodyName}>
          <Text style={styles.contentNormal}>{name}</Text>
        </View>
        <View style={styles.tableBodyRole}>
          <Text style={styles.contentNormal}>{role}</Text>
        </View>
        <View style={styles.tableBodyInfo}>
          <View style={styles.tableBodyColumn}>
            <View style={styles.tableBodyColumnRow}>
              <View style={styles.columnRowCardOne}>
                <Text style={styles.contentNormal}>Email</Text>
              </View>
              <View style={styles.columnRowCardTwo}>
                <Text style={styles.contentNormal}>: </Text>
              </View>
              <View style={styles.columnRowCardThree}>
                <Text style={styles.contentNormal}>{email}</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableBodyColumn}>
            <View style={styles.tableBodyColumnRow}>
              <View style={styles.columnRowCardOne}>
                <Text style={styles.contentNormal}>IP</Text>
              </View>
              <View style={styles.columnRowCardTwo}>
                <Text style={styles.contentNormal}>: </Text>
              </View>
              <View style={styles.columnRowCardThree}>
                <Text style={styles.contentNormal}>{ip}</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableBodyColumn}>
            <View style={styles.tableBodyColumnRow}>
              <View style={styles.columnRowCardOne}>
                <Text style={styles.contentNormal}>{role === 'Signer' && 'Signed at'}</Text>
              </View>
              <View style={styles.columnRowCardTwo}>
                <Text style={styles.contentNormal}>: </Text>
              </View>
              <View style={styles.columnRowCardThree}>
                <View style={styles.dateFlexBox}>
                  <Text style={styles.contentNormal}>
                    {moment(signedDate).format('YYYY-MM-DD')}
                  </Text>
                  <Text style={styles.contentNormal}>
                    {' '}
                    {moment(signedDate).format('h:mm:ss Z')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.tablebodySignature}>
          {hasSigned ? (
            type === 'electronic' || type === 'text' ? (
              <View style={{ position: 'relative', display: 'inline-box', height: '60px' }}>
                <Image
                  alt="sig frame"
                  src={SigningBox}
                  style={{ height: '55px', width: '130px' }}
                />
                <Image
                  alt="Signature"
                  src={signature}
                  style={{
                    position: 'absolute',
                    height: '25px',
                    width: '80px',
                    left: '30',
                    top: '25',
                  }}
                />
              </View>
            ) : (
              <Text style={styles.tableBodySignPassText}>Signed With SingPass</Text>
            )
          ) : hasDeclined ? (
            <Text style={styles.tableBodyVoidedText}>VOIDED</Text>
          ) : (
            <Text style={styles.tableBodyPendingText}>PENDING</Text>
          )}
        </View>
      </View>
      <View style={styles.horizontalBarGray} />
    </View>
  );
}

function ApproverRow({ approver, i }) {
  const { name, email, ip, hasApproved, hasDeclined, approvedDate } = approver;
  return (
    <View style={styles.tableBody} wrap={false}>
      <View style={styles.tableBodyRow}>
        <View style={styles.tableBodyName}>
          <Text style={styles.contentNormal}>{name}</Text>
        </View>
        <View style={styles.tableBodyRole}>
          <Text style={styles.contentNormal}>Approver</Text>
        </View>
        <View style={styles.tableBodyInfo}>
          <View style={styles.tableBodyColumn}>
            <View style={styles.tableBodyColumnRow}>
              <View style={styles.columnRowCardOne}>
                <Text style={styles.contentNormal}>Email</Text>
              </View>
              <View style={styles.columnRowCardTwo}>
                <Text style={styles.contentNormal}>: </Text>
              </View>
              <View style={styles.columnRowCardThree}>
                <Text style={styles.contentNormal}>{email}</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableBodyColumn}>
            <View style={styles.tableBodyColumnRow}>
              <View style={styles.columnRowCardOne}>
                <Text style={styles.contentNormal}>IP</Text>
              </View>
              <View style={styles.columnRowCardTwo}>
                <Text style={styles.contentNormal}>: </Text>
              </View>
              <View style={styles.columnRowCardThree}>
                <Text style={styles.contentNormal}>{ip}</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableBodyColumn}>
            <View style={styles.tableBodyColumnRow}>
              <View style={styles.columnRowCardOne}>
                <Text style={styles.contentNormal}>Approved at</Text>
              </View>
              <View style={styles.columnRowCardTwo}>
                <Text style={styles.contentNormal}>: </Text>
              </View>
              <View style={styles.columnRowCardThree}>
                <View style={styles.dateFlexBox}>
                  <Text style={styles.contentNormal}>
                    {moment(approvedDate).format('YYYY-MM-DD')}
                  </Text>
                  <Text style={styles.contentNormal}>
                    {' '}
                    {moment(approvedDate).format('h:mm:ss Z')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.tablebodySignature}>
          {hasApproved ? (
            <Text style={styles.tableBodyApprovedText}>APPROVED</Text>
          ) : hasDeclined ? (
            <Text style={styles.tableBodyVoidedText}>VOIDED</Text>
          ) : (
            <Text style={styles.tableBodyPendingText}>PENDING</Text>
          )}
        </View>
      </View>
      <View style={styles.horizontalBarGray} />
    </View>
  );
}

function ProjectStatus({ status }) {
  if (!status) return <></>;

  const { backgroundColor, color } = getStatusTagColor(status);

  return (
    <div
      style={{
        display: 'inline-block',
        backgroundColor,
        color,
      }}
    >
      {status.toUpperCase()}
    </div>
  );
}

const DownloadableCertificate = () => {
  const process = useSelector(selectProcess);
  const docInfo = useSelector(selectDocument);
  const [lastUpdated, setLastUpdated] = useState('');
  const router = useHistory();

  if (!Object.keys(process).length) {
    router.push('/');
  }

  useEffect(() => {
    if (process && process.history && process.history.length > 0) {
      setLastUpdated(
        isoToStringWithTimezone(process.history[process.history.length - 1].timestamp)
      );
    }
  }, [process, process.history]);

  const theme = dedocoTheme;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Layout>
          <PDFViewer style={styles.pdf}>
            <Document title="Certificate of Completion - Dedoco Sign Agreement">
              <Page size="A4" style={[styles.page]} wrap>
                <Header docInfo={docInfo} processInfo={process} />
                <View style={styles.sectionTitle}>
                  <Text style={styles.sectionTitleText}>Recipient Evidence </Text>
                </View>
                <View style={styles.tableHeader}>
                  <View style={styles.tableHeaderName}>
                    <Text style={styles.contentBold}>Name</Text>
                  </View>
                  <View style={styles.tableHeaderRole}>
                    <Text style={styles.contentBold}>Role</Text>
                  </View>
                  <View style={styles.tableHeaderInfo}>
                    <Text style={styles.contentBold}>Info</Text>
                  </View>
                  <View style={styles.tableHeaderSignature}>
                    <Text style={styles.contentBold}>Signature/Status</Text>
                  </View>
                </View>
                {process &&
                  process.signers &&
                  process.signers.map((signer, i) => {
                    let type, sig, ip, timestamp;
                    if (Array.isArray(signer.esignatures) && signer.esignatures.length > 0) {
                      type = 'electronic';
                      sig = signer.esignatures[0].signature;
                      ip = signer.esignatures[0].ip_address;
                      timestamp = signer.esignatures[0].timestamp;
                    }
                    if (Array.isArray(signer.digi_signatures) && signer.digi_signatures.length > 0) {
                      type = 'digital';
                      sig = signer.digi_signatures[0].signature;
                      ip = signer.digi_signatures[0].ip_address;
                      timestamp = signer.digi_signatures[0].timestamp;
                    }
                    return (
                      <SignerRow
                        i={i}
                        signer={{
                          role: 'Signer',
                          name: signer.signer_name,
                          email: signer.signer_email,
                          hasSigned: signer.has_signed,
                          hasDeclined: signer.has_declined,
                          type,
                          ip,
                          signature: sig,
                          signedDate: timestamp,
                        }}
                      />
                    );
                  })}
                {/* Approvers */}
                {process &&
                  process.approvers &&
                  process.approvers.map((approver, i) => {
                    return (
                      <ApproverRow
                        key={`approver-${i}`}
                        i={i}
                        approver={{
                          role: 'Approver',
                          name: approver.approver_name,
                          email: approver.approver_email,
                          hasApproved: approver.has_approved,
                          hasDeclined: approver.has_declined,
                          ip: approver.ip_address,
                          approvedDate: approver.timestamp,
                        }}
                      />
                    );
                  })}
                <Footer />
              </Page>
              <Page style={[styles.page]}>
                <Header docInfo={docInfo} processInfo={process} />
                <View style={styles.sectionTitle}>
                  <Text style={styles.sectionTitleText}>Document Process Information </Text>
                </View>
                <View style={styles.tableHeader}>
                  <View style={styles.tableHeaderDate}>
                    <Text style={styles.contentBold}>Date</Text>
                  </View>
                  <View style={styles.tableHeaderHashId}>
                    <Text style={styles.contentBold}>Hash ID</Text>
                  </View>
                  <View style={styles.tableHeaderLog}>
                    <Text style={styles.contentBold}>Logs</Text>
                  </View>
                </View>
                {process &&
                  process.history &&
                  process.history.map((history, index) => {
                    const { action, actor, timestamp, transaction_hash } = history;
                    return (
                      <View wrap={false}>
                        <View style={styles.tableBodyRow}>
                          <View style={styles.tablebodyDate}>
                            <Text style={styles.contentNormal}>
                              {moment(timestamp).format('YYYY-MM-DD h:mm:ss Z')}
                            </Text>
                          </View>
                          <View style={styles.tablebodyHashId}>
                            <Text style={styles.contentNormal}>{ellipsis(transaction_hash)}</Text>
                          </View>
                          <View style={styles.tablebodyLogs}>
                            <View style={styles.column}>
                              <Text style={styles.contentNormal}>{`${actor.name}: ${action}`}</Text>
                            </View>
                          </View>
                        </View>
                        <View style={styles.horizontalBarGray} />
                      </View>
                    );
                  })}
                <View style={{ marginTop: '20px' }} wrap={false}>
                  <View style={styles.moreInfoRow}>
                    <View style={styles.moreInfoColumn}>
                      <View style={styles.moreInfoColumnItem}>
                        <Text style={styles.contentBold}>Document Transmission Method</Text>
                        <Text style={styles.contentNormal}>
                          {process.document_share_method === 'dss'
                            ? 'Dedoco Secure Share'
                            : 'Manual File Transfer'}
                        </Text>
                      </View>
                      <View style={styles.moreInfoColumnItem}>
                        <Text style={styles.contentBold}>Total Action Placed</Text>
                        <Text style={styles.contentNormal}>{process?.history?.length}</Text>
                      </View>
                    </View>
                    <View style={styles.moreInfoColumn}>
                      <View style={styles.moreInfoColumnItem}>
                        <Text style={styles.contentBold}>Recipients Authentication Method</Text>
                        <Text style={styles.contentNormal}>Email</Text>
                      </View>
                      <View style={styles.moreInfoColumnItem}>
                        <Text style={styles.contentBold}>Signing Method</Text>
                        <Text style={styles.contentNormal}>
                          {Array.isArray(process.signers && process.signers[0].esignatures)
                            ? 'Electronic'
                            : 'Digital'}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <Footer />
              </Page>
            </Document>
          </PDFViewer>
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default DownloadableCertificate;
