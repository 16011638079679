import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  StyledTableCell,
  LabelStyled,
  TableStyled,
  TableMobile,
  TableChildren,
  DateRowStyled,
  DateCellStyled,
  DateValueCellStyled,
  HashRowStyled,
  LabelMobileStyled
} from './Remarks.styles';
import TableBody from '@mui/material/TableBody';
import {isoToLocaleString} from '../../utils/dateParser';
import {ellipsis} from '../../utils/ellipsis';
import {getSortedColor} from '../../utils/getRandomColor';

const getRemarkDescription = (action) => {
  switch (action) {
    case "Approve Process":
      return "Completed the approver process";
    case "Decline Process":
      return "Rejected the approver process";
    case "void Business Process":
      return "Rejected the process";
    default:
      return action;
  }
}

const Remarks = ({history}) => {
  return (
    <>
      <TableStyled sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Hash ID</StyledTableCell>
            <StyledTableCell>Log</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {isoToLocaleString(row.timestamp)}
              </StyledTableCell>
              <StyledTableCell>{ellipsis(row.transaction_hash)}</StyledTableCell>
              <StyledTableCell>
                <LabelStyled
                  colorLabel={row.colorLabel}
                  colorText="#FFFFFF">
                  {row.actor.name}
                </LabelStyled>
                {getRemarkDescription(row.action)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableStyled>

      <TableMobile>
        {history?.map((row, index) => (
          <TableChildren key={index}>
            <DateRowStyled>
              <DateCellStyled>Date</DateCellStyled>
              <DateValueCellStyled>{isoToLocaleString(row.timestamp)}</DateValueCellStyled>
            </DateRowStyled>
            <HashRowStyled>
              <DateCellStyled>Hash ID</DateCellStyled>
              <DateValueCellStyled>{ellipsis(row.transaction_hash)}</DateValueCellStyled>
            </HashRowStyled>
            <HashRowStyled>
              <DateCellStyled>Log</DateCellStyled>
              <DateValueCellStyled>
                <LabelMobileStyled
                  colorLabel={row.colorLabel}
                  colorText="#FFFFFF">
                  {row.actor.name}
                </LabelMobileStyled>
                {getRemarkDescription(row.action)}
              </DateValueCellStyled>
            </HashRowStyled>
          </TableChildren>
        ))}
      </TableMobile>
    </>
  )
}

export default Remarks
