export const VerifiedIcon = ({ color = 'white', ...props }) => {
  return (
    <svg
      color={color}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.12467 9.58366L4.97884 8.41699C4.81217 8.25033 4.6144 8.16699 4.38551 8.16699C4.15606 8.16699 3.95801 8.25033 3.79134 8.41699C3.62467 8.58366 3.53801 8.78144 3.53134 9.01032C3.52412 9.23977 3.60384 9.43783 3.77051 9.60449L5.54134 11.3753C5.70801 11.542 5.90245 11.6253 6.12467 11.6253C6.3469 11.6253 6.54134 11.542 6.70801 11.3753L10.2497 7.83366C10.4163 7.66699 10.4997 7.46894 10.4997 7.23949C10.4997 7.0106 10.4163 6.81283 10.2497 6.64616C10.083 6.47949 9.88523 6.39616 9.65634 6.39616C9.4269 6.39616 9.22884 6.47949 9.06217 6.64616L6.12467 9.58366ZM6.99967 17.2712H6.79134C6.7219 17.2712 6.6594 17.2573 6.60384 17.2295C4.7844 16.66 3.2844 15.5314 2.10384 13.8437C0.923286 12.1564 0.333008 10.292 0.333008 8.25033V4.31283C0.333008 3.9656 0.433841 3.6531 0.635508 3.37533C0.836619 3.09755 1.0969 2.89616 1.41634 2.77116L6.41634 0.896159C6.61079 0.826714 6.80523 0.791992 6.99967 0.791992C7.19412 0.791992 7.38856 0.826714 7.58301 0.896159L12.583 2.77116C12.9025 2.89616 13.163 3.09755 13.3647 3.37533C13.5658 3.6531 13.6663 3.9656 13.6663 4.31283V8.25033C13.6663 10.292 13.0761 12.1564 11.8955 13.8437C10.715 15.5314 9.21495 16.66 7.39551 17.2295C7.32606 17.2573 7.19412 17.2712 6.99967 17.2712ZM6.99967 15.5837C8.44412 15.1253 9.63856 14.2087 10.583 12.8337C11.5275 11.4587 11.9997 9.93088 11.9997 8.25033V4.31283L6.99967 2.43783L1.99967 4.31283V8.25033C1.99967 9.93088 2.4719 11.4587 3.41634 12.8337C4.36079 14.2087 5.55523 15.1253 6.99967 15.5837Z"
        fill="currentColor"
      />
    </svg>
  );
};
