import {styled, Table} from '@mui/material';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';

export const LabelStyled = styled('span')((props) => ({
  backgroundColor: props.colorLabel,
  padding: '4px 12px',
  borderRadius: '100px',
  marginRight: '8px',
  color: props.colorText,
  fontWeight: 500,
  fontSize: '12px'
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.supporting.light,
    color: theme.palette.text.secondary,
    padding: `${theme.spacing(5)} 14px !important`,
    borderBottom: `1px solid ${theme.palette.supporting.main}`,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.background.base,
    fontWeight: '400',
    padding: `${theme.spacing(5)} 14px !important`,
    borderBottom: `1px solid ${theme.palette.supporting.main} !important`,
  },
}));

export const TableStyled = styled(Table)(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
}));

export const TableMobile = styled('div')(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  },
}));

export const TableChildren = styled('div')(({theme}) => ({

}));

export const DateRowStyled = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.supporting.light,
  color: theme.palette.text.secondary,
  padding: `${theme.spacing(5)} 14px !important`,
  borderBottom: `1px solid ${theme.palette.supporting.main}`,
  display: 'flex',
  alignItems: 'center',
}));

export const DateCellStyled = styled('div')(({theme}) => ({
  width: '40%'

}));

export const DateValueCellStyled = styled('div')(({theme}) => ({
  width: '50%'
}))

export const HashRowStyled = styled('div')(({theme}) => ({
  fontSize: 14,
  color: theme.palette.background.base,
  fontWeight: '400',
  padding: `${theme.spacing(5)} 14px !important`,
  borderBottom: `1px solid ${theme.palette.supporting.main} !important`,
  display: 'flex',
  alignItems: 'center',
}));

export const LabelMobileStyled = styled('div')((props) => ({
  backgroundColor: props.colorLabel,
  padding: '4px 12px',
  borderRadius: '100px',
  marginRight: '8px',
  color: props.colorText,
  fontWeight: 500,
  fontSize: '12px'
}));
