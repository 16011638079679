import { styled } from '@mui/material';

export const Root = styled('div')(({ theme }) => ({
  height: '100%',
  width: '75%',
  padding: theme.spacing(5),

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
