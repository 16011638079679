import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SignatureES from '../../assets/icons/signature-electric.svg';
import SingPassLogo from '../../assets/icons/singpass-logo-fullcolours.svg';
import {
  classes,
  DateCellStyled,
  DateRowStyled,
  DateValueCellStyled,
  HashRowStyled,
  LabelMobileStyled,
  LabelStyled,
  SignatoriesItem,
  SignatoriesItemEmpty,
  StyledTableCell,
  TableChildren,
  TableMobile,
  TableStyled,
} from './Signatories.styles';

const Signatories = ({ signers }) => {
  return (
    <>
      <TableStyled sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Contact</StyledTableCell>
            <StyledTableCell>Signature</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {signers?.map((row, index) => {
            const isDigitalSignature = row.digi_signatures?.length > 0;
            const signatures = isDigitalSignature ? row.digi_signatures : row.esignatures;

            return <SignatoryRow index={index} signer={row} signatures={signatures} />;
          })}
        </TableBody>
      </TableStyled>

      <TableMobile>
        {signers?.map((row, index) => {
          const isDigitalSignature = row.digi_signatures?.length > 0;
          const signatures = isDigitalSignature ? row.digi_signatures : row.esignatures;

          return <SignatoryRowMobile index={index} signer={row} signatures={signatures} />;
        })}
      </TableMobile>
    </>
  );
};

const SignatoryRow = ({ index, signer, signatures }) => {
  return (
    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <StyledTableCell>
        <LabelStyled colorLabel={signer.colorLabel} colorText="#FFFFFF">
          {signer.signer_name}
        </LabelStyled>
        {!!signatures.length && signatures[0].ip_address}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {signer.signer_email}
      </StyledTableCell>
      <StyledTableCell>
        <SignatureDisplay signatures={signatures} />
      </StyledTableCell>
    </TableRow>
  );
};

const SignatoryRowMobile = ({ index, signer, signatures }) => {
  return (
    <TableChildren key={index}>
      <DateRowStyled>
        <DateCellStyled>Name</DateCellStyled>
        <DateValueCellStyled>
          <LabelMobileStyled colorLabel={signer.colorLabel} colorText="#FFFFFF">
            {signer.signer_name}
          </LabelMobileStyled>
          {!!signatures.length && signatures[0].ip_address}
        </DateValueCellStyled>
      </DateRowStyled>
      <HashRowStyled>
        <DateCellStyled>Contact</DateCellStyled>
        <DateCellStyled>{signer.signer_email}</DateCellStyled>
      </HashRowStyled>
      <HashRowStyled>
        <DateCellStyled>Signature</DateCellStyled>
        <DateCellStyled>
          <SignatureDisplay signatures={signatures} />
        </DateCellStyled>
      </HashRowStyled>
    </TableChildren>
  );
};

const SignatureDisplay = ({ signatures }) => {
  const signedItems = signatures.filter((item) => item.signature);

  return (
    <>
      {signedItems.length === 0 && <SignatoriesItemEmpty>No signature</SignatoriesItemEmpty>}

      {signedItems.map((item, index) => {
        return (
          <SignatoriesItem key={`signatory-item-${index}`}>
            {item.type === 'ndi' ? (
              <>
                <div className={classes.text}>Signed with</div>
                <img className={classes.textImage} src={SingPassLogo} />
              </>
            ) : (
              <>
                <img src={SignatureES} alt="wrapper" />
                <img className={classes.image} src={item.signature} alt="signature" />
              </>
            )}
          </SignatoriesItem>
        );
      })}
    </>
  );
};

export default Signatories;
