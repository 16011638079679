import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const CompanyAttribute = ({ attribute, value }) => {
  return (
    <Box p={1}>
      <Stack mb={1} direction={'row'}>
        <Box width={250}>
          <Typography variant="body_medium" fontWeight={500}>
            {attribute}
          </Typography>
        </Box>
        :
        <Box width={250} ml={10}>
          <Typography variant="body_medium" fontWeight={500} sx={{ whiteSpace: 'pre-line' }}>
            {value}
          </Typography>
        </Box>
      </Stack>
      <Divider />
    </Box>
  );
};
