import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
// import 'flag-icon-css/css/flag-icon.min.css';

const PREFIX = 'LanguageSelector';

export const classes = {
  button: `${PREFIX}-button`,
  expandIcon: `${PREFIX}-expand-icon`,
  menuBox: `${PREFIX}-menu-box`,
};

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  [`& .${classes.button}`]: {
    padding: theme.spacing(2, 2.5),
  },
  [`& .${classes.expandIcon}`]: {
    marginLeft: theme.spacing(1.125),
    fontSize: theme.spacing(4.75),
  },
  [`& .${classes.menuBox}`]: {
    padding: theme.spacing(1),
  },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .MuiPaper-root`]: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    backgroundColor: theme.palette.background.main,
  },
  [`& .MuiList-root`]: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
    boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.12)',
  },
  [`& .MuiMenuItem-root`]: {
    padding: theme.spacing(3, 2),
    width: theme.spacing(28),
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(2),
  },
  [`& .MuiMenuItem-root:hover`]: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.supporting.light,
  },
}));
