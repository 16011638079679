import { styled } from '@mui/material';

const PREFIX = 'Card';

export const classes = {
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
};

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '33%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.background.paper}`,
  padding: theme.spacing(4),
  marginRight: theme.spacing(4),
  marginTop: theme.spacing(4),

  [theme.breakpoints.down('md')]: {
    width: '100%',
    boxSizing: 'border-box',
  },

  [`&:last-child`]: {
    marginRight: 0,
  },

  [`& .${classes.title}`]: {
    marginTop: '14px',
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.description}`]: {
    textAlign: 'left',
  },
}));
