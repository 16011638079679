import {Box, styled, Tab} from '@mui/material';

const PREFIX = 'DedocoDocument';

export const classes = {
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  titleTable: `${PREFIX}-titleTable`,
};

export const Root = styled('div')(({ theme }) => ({
  textAlign: 'left',
  display: 'flex',

  [`& .${classes.title}`]: {
    display: 'block',
    textAlign: 'left',
  },

  [`& .${classes.description}`]: {
    display: 'block',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.titleTable}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '34px',
  },
}));


export const BoxStyled = styled(Box)(({theme}) => ({
  display: 'none',

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    overflow: 'hidden',
  },
}));

export const TabStyled = styled(Tab)(({theme}) => ({
  width: '50%',
  textAlign: 'center',
  textTransform: 'capitalize'
}));

export const BoxDesktopStyled = styled('div')(({theme}) => ({
  display: 'flex',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
