import { createTheme, experimental_sx as sx } from '@mui/material';

const dedocoTheme = createTheme({
  palette: {
    primary: {
      main: '#6333FF',
      light: '#C1ADFF',
      dark: '#3F21A3',
      extraLight: '#D9CDFF',
    },
    secondary: {
      main: '#FF6600',
      light: '#FFA366',
      dark: '#B94A00',
    },
    error: {
      main: '#F53838',
      light: '#F98888',
      dark: '#9D2424',
    },
    warning: {
      light: '#FED666E',
      main: '#FECC4A',
      dark: '#CFA63C',
    },
    info: {
      light: '#8AE0EB',
      main: '#3CCBDE',
      dark: '26828E',
    },
    success: {
      light: '#A3FFBD',
      main: '#66FF91',
      dark: '#62A35D',
      primary: '#1A936F',
    },
    darkGrey: {
      light: '#949495',
      main: '#4D4D4F',
      dark: '#1F1F20',
    },
    lightGrey: {
      light: '#EBEBEB',
      main: '#DEDEDE',
      dark: '#8E8E8E',
      border: '#EDEDF0',
      primary: '#828282',
    },
    background: {
      paper: '#FAFAFB',
      main: '#FFFFFF',
      base: '#1A1A34',
    },
    supporting: {
      light: '#F9F7FF',
      main: '#F3EFFF',
      dark: '#E6DEFF',
      extraLight: '#FCFBFF',
    },
    stroke: {
      light: '#FAFAFB',
      main: '#F6F6F7',
      dark: '#EDEDEF ',
    },
    text: {
      primary: '#1A1A34',
      secondary: '#767685',
      tertiary: '#A3A3AE',
      black: '#000000',
    },
  },
  spacing: 4,
  typography: {
    fontFamily: [
      'HCo Gotham',
      'Open Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    headline_large: {
      fontFamily: 'HCo Gotham',
      fontSize: '2rem',
      lineHeight: '2.5rem',
      lineHeight: '40px',
      letterSpacing: 0,
    },
    headline_medium: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
      letterSpacing: 0,
    },
    headline_small: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      letterSpacing: 0,
    },
    title_large: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.25rem',
      letterSpacing: 0,
    },
    title_medium: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      letterSpacing: 0,
    },
    title_small: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      letterSpacing: 0,
    },
    label_large: {
      fontFamily: 'HCo Gotham',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.25rem',
      letterSpacing: 0,
    },
    label_medium: {
      fontFamily: 'HCo Gotham',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      letterSpacing: 0,
    },
    label_small: {
      fontFamily: 'HCo Gotham',
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      letterSpacing: 0,
    },
    body_large: {
      fontFamily: 'HCo Gotham',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.25rem',
      letterSpacing: 0,
    },
    body_medium: {
      fontFamily: 'HCo Gotham',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      letterSpacing: 0,
      wordBreak: 'break-word',
    },
    body_small: {
      fontFamily: 'HCo Gotham',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      letterSpacing: 0,
    },
    text_small: {
      fontFamily: 'HCo Gotham',
      fontWeight: 400,
      fontSize: '0.5rem',
      lineHeight: '0.625rem',
      letterSpacing: 0,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained-round', color: 'primary' },
          style: sx({
            textTransform: 'none',
            borderRadius: '100px',
            color: '#fff',
          }),
        },
        {
          props: { variant: 'tinted', color: 'primary' },
          style: sx({
            textTransform: 'none',
            borderRadius: 2,
          }),
        },
      ],
      styleOverrides: {
        root: sx({
          fontSize: '14px',
          lineHeight: '18px',
          textTransform: 'none',
          padding: '11px 16px',
          fontWeight: '500',
          '&.Mui-disabled': {
            filter: 'opacity(50%)',
          },
        }),
      },
    },
  },
});

export { dedocoTheme };
