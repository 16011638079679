import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  StyledTableCell,
  TableStyled,
  TableMobile,
  TableChildren,
  DateRowStyled,
  HashRowStyled,
  DateCellStyled,
  DateValueCellStyled
} from './DocumentHashHistory.styles';
import {ellipsis} from '../../utils/ellipsis';
import {isoToLocaleString} from '../../utils/dateParser';

const DocumentHashHistory = ({history}) => {
  return (
    <div>
      <TableStyled sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Transaction Date</StyledTableCell>
            <StyledTableCell>Hash ID</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {isoToLocaleString(row.timestamp)}
              </StyledTableCell>
              <StyledTableCell>{ellipsis(row.transaction_hash)}</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableStyled>

      <TableMobile>
        {history?.map((row, index) => (
          <TableChildren key={index}>
            <DateRowStyled>
              <DateCellStyled>Transaction Date</DateCellStyled>
              <DateValueCellStyled>{isoToLocaleString(row.timestamp)}</DateValueCellStyled>
            </DateRowStyled>
            <HashRowStyled>
              <DateCellStyled>Hash ID</DateCellStyled>
              <DateValueCellStyled>{ellipsis(row.transaction_hash)}</DateValueCellStyled>
            </HashRowStyled>
          </TableChildren>
        ))}
      </TableMobile>
    </div>
  )
}

export default DocumentHashHistory;
