import { Dialog, styled, Typography } from '@mui/material';

const PREFIX = 'Sidebar';

export const classes = {
  ImageDocument: `${PREFIX}-ImageDocument`,
  ViewDocumentButton: `${PREFIX}-ViewDocumentButton`,
  EyeIconDocument: `${PREFIX}-EyeIconDocument`,
  DividerDocument: `${PREFIX}-DividerDocument`,
  HeadingSidebar: `${PREFIX}-HeadingSidebar`,
  TextCard: `${PREFIX}-TextCard`,
  TitleCard: `${PREFIX}-TitleCard`,
  ContentModal: `${PREFIX}-ContentModal`,
  CloseModal: `${PREFIX}-CloseModal`,
  HeadingPdf: `${PREFIX}-HeadingPdf`,
  InfoPdf: `${PREFIX}-InfoPdf`,
  NamePdf: `${PREFIX}-NamePdf`,
  PagePdf: `${PREFIX}-PagePdf`,
  ZoomPdf: `${PREFIX}-ZoomPdf`,
  ToggleZoomPdf: `${PREFIX}-ToggleZoomPdf`,
  ZoomIn: `${PREFIX}-ZoomIn`,
  ZoomOut: `${PREFIX}-ZoomOut`,
  PercentViewPdf: `${PREFIX}-PercentViewPdf`,
};

export const Root = styled('div')(({ theme }) => ({
  width: '25%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
  boxSizing: 'border-box',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },

  [`& .${classes.DividerDocument}`]: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.stroke.dark,
  },

  [`& .${classes.HeadingSidebar}`]: {
    marginBottom: theme.spacing(4),
  },
}));

export const ViewDocumentStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: `${theme.spacing(4)} 0`,

  [`& .${classes.ImageDocument}`]: {
    width: '100%',
    borderRadius: '8px',
  },

  [`& .${classes.ViewDocumentButton}`]: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '15px',
    color: theme.palette.primary.main,
    backgroundColor: '#F3EEFF',
    width: 'calc(100% - 16px)',
    padding: theme.spacing(2, 16),
    fontSize: '14px',
    fontWeight: 700,
    border: `1px solid ${theme.palette.supporting.dark}`,
    borderRadius: '8px',

    [`& .${classes.EyeIconDocument}`]: {
      marginRight: theme.spacing(2.5),
    },
  },
}));

export const InfoCardStyled = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.stroke.dark}`,
  padding: theme.spacing(4, 0),

  overflowX: 'scroll',

  [`& .${classes.TextCard}`]: {
    color: theme.palette.text.secondary,
  },

  [`& .${classes.TitleCard}`]: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const StatusCardStyled = styled(Typography)((props) => ({
  color: props.color,
  backgroundColor: props.background,
  fontSize: '10px',
  padding: '6px',
  borderRadius: '4px',
}));

export const ModalStyled = styled(Dialog)(({ theme }) => ({
  ['& .MuiPaper-root']: {
    width: '100%',
    maxWidth: '960px',
    height: '720px',
    backgroundColor: theme.palette.background.main,
    borderRadius: '8px',
    padding: theme.spacing(6),
    overflow: 'inherit',

    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxWidth: '343px',
    },
  },

  [`& .${classes.CloseModal}`]: {
    position: 'absolute',
    right: '-52px',
    top: '0',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      right: '20px',
      top: '10px',
    },
  },

  ['& .react-pdf__Page']: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(8),

    ['& .react-pdf__Page__canvas']: {
      borderRadius: '8px',
    },
  },

  [`& .${classes.ContentModal}`]: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    borderRadius: '8px',
    padding: theme.spacing(6),
    boxSizing: 'border-box',
    overflow: 'hidden',
  },

  [`& .${classes.HeadingPdf}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.InfoPdf}`]: {
    display: 'flex',
    flexDirection: 'column',

    [`& .${classes.NamePdf}`]: {
      color: 'rgba(26, 26, 52, 0.8)',
    },

    [`& .${classes.PagePdf}`]: {
      color: 'rgba(26, 26, 52, 0.4)',
    },
  },

  [`& .${classes.ZoomPdf}`]: {
    display: 'flex',
    alignItems: 'center',

    [`& .${classes.ToggleZoomPdf}`]: {
      backgroundColor: '#6531ff0f',
      width: '32px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    [`& .${classes.ZoomIn}`]: {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },

    [`& .${classes.ZoomOut}`]: {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },

    [`& .${classes.PercentViewPdf}`]: {
      backgroundColor: '#6531ff0f',
      color: '#6531ff',
      width: '55px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 1px',
    },
  },
}));

export const TitleModal = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(8),
  },
}));

export const PdfDocumentStyled = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  height: '100%',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    overflow: 'scroll',
    display: 'flex',
  },
}));
