import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChooseFile from '../../assets/icons/choose-file.svg';
import DropboxIcon from '../../assets/icons/dropbox.svg';
import GoogleIcon from '../../assets/icons/google.svg';
import MicrosoftIcon from '../../assets/icons/microsoft.svg';
import { verifyWithDocHash } from '../../lib/api/verify';
import {
  loadFileAsync,
  updateAllResults,
  updateHash,
  updateResult,
} from '../../redux/features/document/documentSlice';
import { getFileBase64 } from '../../utils/base64';
import { getFileHash } from '../../utils/hash';
import {
  CancelButton,
  classes,
  DividerStyled,
  ProgressBarBox,
  Root,
  UploadFileStyled,
  VerifyingBox,
} from './UploadFile.styles';

const UploadButton = ({ icon, text, handleUpload }) => {
  return (
    <div>
      <UploadFileStyled onClick={handleUpload}>
        <img alt="icon" src={icon} />
        <Typography variant="label_medium" className={classes.typeUpload}>
          {text}
        </Typography>
      </UploadFileStyled>
    </div>
  );
};

const UploadFile = ({ setDocNotFound, setMultiDocFound }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fileName, setFileName] = useState('');

  const [isFileReceived, setIsFileReceived] = useState(false);

  // dropzone
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) setIsFileReceived(true);

      setFileName(file.path);
      const fileType = file.path
        .substring(file.path.lastIndexOf(".") + 1, file.path.length)
        .toLowerCase();

      switch (fileType) {
        case 'pdf':
          // code block
          const fileHash = await getFileHash(file);
          const base64File = await getFileBase64(file);
          try {
            verifyWithDocHash(fileHash)
              .then((results) => {
                dispatch(loadFileAsync(base64File));
                dispatch(updateHash(fileHash));
                if (results.length > 1) {
                  dispatch(updateAllResults(results));
                  setMultiDocFound(true);
                  setProgress(100);

                  setTimeout(() => {
                    handleCancelVerify();
                  }, 800);
                } else {
                  dispatch(updateResult(results[0]));
                  setProgress(100);
                  setTimeout(() => {
                    history.push('/document');
                  }, 800);
                }
              })
              .catch(() => {
                setIsFileReceived(false);
                setDocNotFound(true);
                handleCancelVerify();
              });
          } catch {
            // no matching
            setIsFileReceived(false);
            setDocNotFound(true);
            handleCancelVerify();
          }
          break;
        case 'opencert':
        case 'oa':
          // code block
          let document = '';
          let reader = new FileReader();

          reader.readAsText(file);

          reader.onload = function () {
            document = reader.result;

            history.push({
              pathname: '/opencert',
              state: { document: document },
            });
          };

          setIsFileReceived(false);
          break;
        default:
          // code block
          setDocNotFound(true);
          setIsFileReceived(false);
      }
    },
    [dispatch, history]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: 'application/pdf, .opencert, .oa',
    noClick: true,
  });
  const isDisplayUploadButton = false; // Will update after integrate cloud => dimensions.width > 600;

  const [progress, setProgress] = useState(1);
  useEffect(() => {
    if (isFileReceived) {
      const timer = setInterval(() => {
        if (progress < 100) {
          setProgress((progress) => progress + 1);
        } else {
          setProgress(100);
          clearInterval(timer);
        }
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }
  }, [isFileReceived, progress]);

  const description = isDisplayUploadButton
    ? 'Drag and drop document anywhere'
    : 'Tap anywhere to upload your document';

  const handleCancelVerify = () => {
    setIsFileReceived(false);
    setFileName('');
    setProgress(0);
  };

  return (
    <Root>
      <div className={classes.box} {...getRootProps()}>
        {isFileReceived ? (
          <VerifyingBox>
            <Typography variant="label_medium" className={classes.verifyText}>
              Verifying
            </Typography>
            <Typography variant="label_medium" className={classes.filename}>
              {' ' + fileName}
            </Typography>
            <ProgressBarBox variant="determinate" value={progress} />
            <CancelButton onClick={handleCancelVerify}>Cancel</CancelButton>
          </VerifyingBox>
        ) : (
          <>
            <Typography variant="label_medium" className={classes.boxDescription}>
              {description}
            </Typography>

            <DividerStyled>
              <span className={classes.dividerItem}></span>
              <Typography variant="text_small" className={classes.text}>
                OR
              </Typography>
              <span className={classes.dividerItem}></span>
            </DividerStyled>

            <div>
              <input {...getInputProps()} />
              <UploadButton icon={ChooseFile} text="Choose Files" handleUpload={open} />
            </div>

            {isDisplayUploadButton && (
              <>
                <UploadButton icon={GoogleIcon} text="Google Docs" />
                <UploadButton icon={MicrosoftIcon} text="Microsoft 365" />
                <UploadButton icon={DropboxIcon} text="Dropbox" />
              </>
            )}
          </>
        )}
      </div>
    </Root>
  );
};

export default UploadFile;
