import { useHistory, useLocation } from 'react-router-dom';
import { Button, Typography, AppBar, Toolbar } from '@mui/material';
import BackIcon from '../../assets/icons/back.svg';
import { dedocoTheme } from '../../themes/dedocoTheme';
import { Root, classes } from './Header.styles';

const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const isBuilderApp = location.search.replace(/(.*)=/, '') === 'builder';
  const isDocument = location.pathname.replace('/', '') === 'document';

  const backToSiteContent = isBuilderApp ? 'Return to Dashboard' : 'Return to Dedoco Homepage';

  const handleBackSite = () => {
    isBuilderApp
      ? window.location.replace('https://app.dedoco.com')
      : window.location.replace('https://dedoco.com');
  };

  const handleAnotherDocument = () => {
    history.push('/');
  };

  return (
    <Root>
      <Button className={classes.button} onClick={handleBackSite}>
        <img alt="back-icon" src={BackIcon} />
        <Typography className={classes.buttonText} variant="label_medium">
          {backToSiteContent}
        </Typography>
      </Button>

      {isDocument && (
        <Button className={classes.anotherDocument} onClick={handleAnotherDocument}>
          <Typography variant="label_medium">Verify another document</Typography>
        </Button>
      )}
    </Root>
  );
};

export default Header;

export const Navbar = ({ children, ...props }) => {
  return (
    <AppBar
      {...props}
      position="static"
      sx={{
        boxShadow: 'unset',
        backgroundColor: dedocoTheme.palette.stroke.main,
        height: '4rem',
      }}
    >
      <Toolbar variant="dense" sx={{ height: '100%' }}>
        {children}
      </Toolbar>
    </AppBar>
  );
};
