import { createSlice } from '@reduxjs/toolkit';
import { ProjectType } from '../../../constants/Project';

const initialState = {
  base64: '',
  hash: '',
  allResults: [],
  // dSign
  document: { id: '', name: '' },
  documentId: '',
  process: {},
  processId: '',
  // dForm
  form: { id: '', name: '' },
  formId: '',
  submission: {},
  submissionId: '',

  resultType: '',
};

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    loadFile: (state, action) => {
      state.base64 = action.payload;
    },
    updateHash: (state, action) => {
      state.hash = action.payload;
    },

    updateAllResults: (state, action) => {
      state.allResults = [...action.payload];
    },
    updateResult: (state, action) => {
      const result = action.payload;
      state.resultType = result.type;
      switch (result.type) {
        case ProjectType.Form:
          state.form = result.form;
          state.formId = result.form.id;

          state.submission = result.submission;
          state.submissionId = result.submission.id;
          break;
        case ProjectType.Sign:
        default:
          state.document = result.document;
          state.documentId = result.document.id;

          state.process = result.process;
          state.processId = result.process.id;
      }
    },
    selectResult: (state, action) => {
      state.process = state.allResults[action.payload].process;
      state.processId = state.allResults[action.payload].process.id;

      state.document = state.allResults[action.payload].document;
      state.documentId = state.allResults[action.payload].document.id;
    },
    reset: (state) => initialState,
  },
});

export const { loadFile, updateHash, reset, updateAllResults, updateResult, selectResult } =
  documentSlice.actions;

export const loadFileAsync = (base64) => (dispatch) => {
  dispatch(loadFile(base64));
};

export const clearResult = reset; // just an alias name

export const selectProcess = (state) => state.document.process;
export const selectDocument = (state) => state.document.document;
export const selectForm = (state) => state.document.form;
export const selectSubmission = (state) => state.document.submission;
export const selectResultType = (state) => state.document.resultType;

export const selectHash = (state) => state.document.hash;

export const selectAllResults = (state) => state.document.allResults;

export default documentSlice.reducer;
