export const isoToLocaleString = (iso) => {
  if (!iso) return '';
  return new Date(iso).toLocaleString();
};

export const isoToString = (iso) => {
  if (!iso) return '';
  return new Date(iso).toString();
};

export const isoToStringWithTimezone = (iso) => {
  if (!iso) return '';
  return new Date(iso).toString().split(' ').slice(1).join(' ').split(' (')[0];
};

export const convertDDMMYYYYtoDate = (dateString) => {
  if (!dateString) return '';
  const dateParts = dateString.split('/');

  // month is 0-based, that's why we need dataParts[1] - 1
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
};
