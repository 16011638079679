export const ellipsis = (s) => {
  if (!s) return "";
  if (/@.*\..*/.test(s)) {
    const emailTwoParts = s.split("@");
    return `${ellipsis(emailTwoParts[0])}@${emailTwoParts[1]}`;
  }

  let head, tail;
  if (s.length > 100) {
    head = 13;
    tail = 12;
  } else {
    // ~1/4 of original length
    head = Math.ceil(s.length / 8);
    tail = Math.floor(s.length / 8);
  }

  return `${s.substring(0, head)}...${s.substring(s.length - tail)}`;
};
