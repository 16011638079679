export function getStatusTagColor(status) {
  if (!status) return { backgroundColor: "#fff", color: "#000" };

  switch (status.toUpperCase()) {
    case "COMPLETED":
      return { backgroundColor: "#DAEAEA", color: "#27AE60" };
    case "PENDING":
      return { backgroundColor: "#FFD7BA", color: "#FF842B" };
    case "VOIDED":
      return { backgroundColor: "#DB7555", color: "#FFECE5" };
    case "EXPIRED":
      return { backgroundColor: "#F2C94C", color: "#FFFFFF" };
    default:
      return { backgroundColor: "#fff", color: "#000" };
  }
}
