export const BurgerMenuIcon = ({ color = '#FD6565', ...props }) => {
  return (
    <svg
      color={color}
      width="{14}"
      height="{11}"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.09375 9.78125H12.9062M1.09375 5.28125H12.9062M1.09375 0.78125H12.9062"
        stroke="white"
        strokewidth="1.5"
        strokelinecap="round"
        strokelinejoin="round"
      />
    </svg>
  );
};
