import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProjectType } from '../../../constants/Project';
import { selectAllResults, updateResult } from '../../../redux/features/document/documentSlice';
import { ellipsis } from '../../../utils/ellipsis';
import { classes, Root } from './MultipleDocumentSelector.style';

function MultipleDocumentSelector({ openModal, onClose }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedDoc, setSelectedDoc] = useState('');
  const allResults = useSelector(selectAllResults);
  const history = useHistory();

  const handleOnSelect = (event) => {
    setSelectedDoc(event.target.value);
    dispatch(updateResult(allResults[event.target.value]));
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  const routeToDocPage = () => {
    history.push('/document');
  };

  return (
    <Root open={open} onClose={handleClose} className="shafa" fullScreen={fullScreen}>
      <Grid container direction="row" alignItems="flex-start" className={classes.gridContainer}>
        <Grid className={classes.content} direction="column">
          <div className={classes.topGrayBar}></div>
          <DialogTitle className={classes.title}>Choose the right project version</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.subtitle} variant="body_small">
              This document exists in multiple projects. Please make sure that you are choosing the
              right version
            </DialogContentText>
            <FormControl>
              <RadioGroup value={selectedDoc} onChange={handleOnSelect}>
                <Paper>
                  <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" className={classes.tableHeadColumn}>
                            <Typography
                              className={[classes.headerText, classes.leftMargin16]}
                              variant="title_small"
                            >
                              Creation date
                            </Typography>
                          </TableCell>
                          <TableCell align="left" className={classes.tableHeadColumn}>
                            <Typography className={classes.headerText} variant="title_small">
                              Project name
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={[classes.tableHeadColumnSlim, classes.hiddenOnMobile]}
                          >
                            <Typography
                              className={classes.headerText}
                              sx={{ width: '150px' }}
                              variant="title_small"
                            >
                              Creator
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={[classes.tableHeadColumnSlim, classes.hiddenOnMobile]}
                          >
                            <Typography
                              className={classes.headerText}
                              sx={{ width: '150px' }}
                              variant="title_small"
                            >
                              Type
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={[classes.tableHeadColumn, classes.hiddenOnMobile]}
                          >
                            <Typography className={classes.headerText} variant="title_small">
                              Last updated
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allResults.map((row, i) => {
                          let dateCreated, projectName, creatorName, type;
                          let txHash = '';
                          const lastModified = row.timestamp;

                          switch (row.type) {
                            case ProjectType.Form:
                              dateCreated = row.submission.createdAt;
                              projectName = row.form.name;
                              creatorName = row.submission.formFiller.name;
                              type = 'dForm';
                              break;
                            case ProjectType.Sign:
                            default:
                              dateCreated = row.document.date_created;
                              projectName = row.document.name;
                              txHash = row.process.history[0].transaction_hash;
                              creatorName = row.process.owner.name;
                              type = 'dSign';
                              break;
                          }

                          return (
                            <TableRow hover role="checkbox" key={i}>
                              <TableCell>
                                <Typography
                                  className={[classes.leftMargin16, classes.creationDate]}
                                  variant="body_small"
                                >
                                  <span>{moment(dateCreated).format(`D/MM/YYYY`)} &nbsp;</span>
                                  <span>{moment(dateCreated).format(`hh:mm:ss A`)}</span>
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.columnDocInfo}>
                                <Typography className={classes.docInfo} variant="body_small">
                                  <span>
                                    <b>{projectName}</b>
                                  </span>
                                  <span>{ellipsis(txHash)}</span>
                                </Typography>
                                <FormControlLabel
                                  value={i}
                                  control={<Radio />}
                                  className={classes.radioButtonMobile}
                                />
                              </TableCell>
                              <TableCell className={classes.hiddenOnMobile}>
                                <Typography variant="body_small" className={classes.ownerName}>
                                  {creatorName}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.columnDocInfo}>
                                <Typography variant="body_small">{type}</Typography>
                              </TableCell>
                              <TableCell
                                className={`${classes.hiddenOnMobile} ${classes.columnLastUpdatedInfo}`}
                              >
                                <Typography variant="body_small">
                                  <span>{moment(lastModified).format(`D/MM/YYYY hh:mm:ss A`)}</span>
                                </Typography>
                                <FormControlLabel
                                  value={i}
                                  control={<Radio />}
                                  className={classes.radioButton}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            {selectedDoc && (
              <Typography variant="label_small" className={classes.selectedDoc}>
                {allResults[selectedDoc].document?.name || allResults[selectedDoc].form?.name}
              </Typography>
            )}
            <Button
              disabled={!selectedDoc}
              className={classes.selectButton}
              onClick={routeToDocPage}
            >
              Verify selected document
            </Button>
          </DialogActions>
        </Grid>
        <Button className={classes.closeButton} onClick={handleClose}>
          X
        </Button>
      </Grid>
    </Root>
  );
}

export default MultipleDocumentSelector;
