import { StyleSheet, Font } from '@react-pdf/renderer';
import gothamThin from '../../fonts/woff/Gotham-Thin_Web.woff';
import gothamXLight from '../../fonts/woff/Gotham-XLight_Web.woff';
import gothamLight from '../../fonts/woff/Gotham-Light_Web.woff';
import gothamNormal from '../../fonts/woff/Gotham-Book_Web.woff';
import gothamMedium from '../../fonts/woff/Gotham-Medium_Web.woff';
import gothamBold from '../../fonts/woff/Gotham-Bold_Web.woff';
import gothamExtraBold from '../../fonts/woff/Gotham-Black_Web.woff';
import gothamUltraBold from '../../fonts/woff/Gotham-UltraItalic_Web.woff';

Font.register({
  family: 'Gotham',
  fonts: [
    {
      src: gothamThin,
      fontWeight: 100,
    },
    {
      src: gothamXLight,
      fontWeight: 200,
    },
    {
      src: gothamLight,
      fontWeight: 300,
    },
    {
      src: gothamNormal,
      fontWeight: 400,
    },
    {
      src: gothamMedium,
      fontWeight: 500,
    },
    {
      src: gothamBold,
      fontWeight: 700,
    },
    {
      src: gothamExtraBold,
      fontWeight: 800,
    },
    {
      src: gothamUltraBold,
      fontWeight: 900,
    },
  ],
});

export const styles = StyleSheet.create({
  pdf: {
    height: '100%',
    width: '100%',
  },
  page: {
    borderTop: '11px solid #1A1A34',
    borderLeft: '11px solid #1A1A34',
    borderRight: '11px solid #1A1A34',
  },
  header: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  title: {
    color: '#000',
    fontSize: '25',
    fontWeight: 'bold',
    fontFamily: 'Gotham',
  },
  text: {
    marginTop: '10px',
    color: '#000',
    fontSize: '13px',
    fontWeight: 'bold',
    fontFamily: 'Gotham',
  },
  headerContentText: {
    fontSize: '9',
    color: '#404040',
    flexGrow: 1,
    textAlign: 'justify',
    marginBottom: '5px',
    fontFamily: 'Gotham',
    fontWeight: '500',
  },
  horizontalBarGray: {
    backgroundColor: '#A3A3AE',
    height: '1px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '97%',
    marginVertical: '5px',
  },
  sectionTitle: {
    flexDirection: 'row',
    paddingHorizontal: '15px',
    paddingVertical: '7px',
    fontFamily: 'Gotham',
  },
  sectionTitleText: {
    fontSize: '13',
    fontWeight: 700,
    color: '#000000',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    height: '15px',
    marginTop: '5px',
  },
  contentBold: {
    fontSize: '12',
    color: '#000',
    marginBottom: '5px',
    fontWeight: 700,
    fontFamily: 'Gotham',
  },
  contentNormal: {
    fontSize: '10',
    flex: 1,
    color: '#000',
    textAlign: 'justify',
    fontFamily: 'Gotham',
    fontWeight: 500,
  },
  subtitleLight: {
    fontSize: '10',
    color: '#B0B0B0',
    flexGrow: 1,
    textAlign: 'justify',
    fontFamily: 'Gotham',
  },
  tableHeaderName: {
    flex: 2.5,
    alignSelf: 'center',
    alignItems: 'left',
    paddingLeft: '15px',
    paddingVertical: '5px',
    fontFamily: 'Gotham',
  },
  tableHeaderRole: {
    flex: 2,
    alignSelf: 'center',
    alignItems: 'left',
    paddingVertical: '5px',
  },
  tableHeaderInfo: {
    flex: 4.3,
    alignSelf: 'center',
    alignItems: 'left',
    paddingVertical: '5px',
  },
  tableHeaderSignature: {
    flex: 3.2,
    alignSelf: 'center',
    alignItems: 'left',
    paddingVertical: '5px',
    paddingRight: '15px',
  },
  tableHeaderDate: {
    flex: 4,
    alignSelf: 'center',
    alignItems: 'left',
    paddingVertical: '5px',
    paddingLeft: '15px',
  },
  tableHeaderHashId: {
    flex: 3,
    alignSelf: 'center',
    alignItems: 'left',
    paddingVertical: '5px',
  },
  tableHeaderLog: {
    flex: 5,
    alignSelf: 'center',
    alignItems: 'left',
    paddingVertical: '5px',
  },
  tableBodyRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '13px',
    marginTop: '13px',
  },
  tableBodyName: {
    flex: 2.5,
    alignSelf: 'top',
    alignItems: 'left',
    paddingLeft: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  tableBodyRole: {
    flex: 2,
    alignSelf: 'top',
    alignItems: 'left',
  },
  tableBodyInfo: {
    flex: 4.3,
    alignSelf: 'top',
    alignItems: 'left',
  },
  tablebodySignature: {
    flex: 3.2,
    alignSelf: 'center',
    alignItems: 'Left',
    paddingRight: '15px',
  },
  tablebodyDate: {
    flex: 4,
    alignSelf: 'top',
    alignItems: 'left',
    paddingLeft: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  tablebodyHashId: {
    flex: 3,
    alignSelf: 'top',
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  tablebodyLogs: {
    flex: 5,
    alignSelf: 'top',
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  tableBodyColumn: {
    flex: 1,
    flexDirection: 'column',
  },
  tableBodyColumnRow: {
    flexGrow: 4,
    flexDirection: 'row',
  },
  columnRowCardOne: {
    flex: 3,
  },
  columnRowCardTwo: {
    flex: 1,
  },
  columnRowCardThree: {
    flex: 5,
  },
  dateFlexBox: {
    alignSelf: 'top',
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  tableBodyPendingText: {
    fontSize: '12',
    color: '#FF6600',
    marginBottom: '5px',
    fontWeight: 700,
    fontFamily: 'Gotham',
  },
  tableBodyVoidedText: {
    fontSize: '12',
    color: '#FF2E2E',
    marginBottom: '5px',
    fontWeight: 700,
    fontFamily: 'Gotham',
  },
  tableBodySignPassText: {
    fontSize: '12',
    color: '#D10000',
    marginBottom: '5px',
    fontWeight: 700,
    fontFamily: 'Gotham',
  },
  tableBodyApprovedText: {
    fontSize: '12',
    color: '#27AE60',
    marginBottom: '5px',
    fontWeight: 700,
    fontFamily: 'Gotham',
  },
  moreInfoRow: {
    flexDirection: 'row',
    paddingHorizontal: '15px',
  },
  moreInfoColumn: {
    flex: 1,
    flexDirection: 'column',
  },
  moreInfoColumnItem: {
    marginBottom: '15px',
  },
  footer: {
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 100,
  },
  timestamp: {
    fontSize: 8,
    fontWeight: 400,
    marginLeft: '32px',
    marginBottom: '8px',
  },
});
