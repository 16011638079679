// CompanyDetails.js
import { Grid, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { convertDDMMYYYYtoDate } from '../../../utils/dateParser';
import { CompanyAttribute } from './CompanyAttribute';

const CompanyDetails = ({ companyData }) => {
  const {
    uen,
    entityName,
    formerNames,
    incorporationDate,
    companyType,
    changeOfNameDate,
    status,
    statusDate,
    address,
    changeOfAddressDate,
    agm,
    verifyLink,
  } = companyData;

  const getFormattedDate = (dateString) => {
    if (!dateString) return '';

    return convertDDMMYYYYtoDate(dateString)
      .toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
      .toUpperCase();
  };

  const dataObject = {
    'Name of Company': entityName,
    'Former name (if any)': formerNames?.map((entry) => entry.name),
    'Date of Change of Name': getFormattedDate(changeOfNameDate),
    UEN: uen,
    'Incorporation Date': getFormattedDate(incorporationDate),
    'Company Type': companyType,
    'Status of Company': status?.toUpperCase(),
    'Status Date': getFormattedDate(statusDate),
    'Registered Office Address': address?.formattedAddress,
    'Date of Address': getFormattedDate(changeOfAddressDate),
    'Date of Last AGM': getFormattedDate(agm?.[0]?.lastAgmDate),
    'Date of Last AR': getFormattedDate(agm?.[0]?.lastArDate),
    'FYE As At Date of Last AR': getFormattedDate(agm?.[0]?.lastArDateFye),
  };

  const handleVerifyAcra = () => {
    window.open(verifyLink, '_blank');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={2}>
        <Paper elevation={3} sx={{ p: 5 }}>
          {Object.entries(dataObject).map(([attribute, value], index) => (
            <CompanyAttribute
              key={`attribute-${value?.toString()}-${index}`}
              attribute={attribute}
              value={value}
            />
          ))}
          <Stack direction="row-reverse">
            <Button sx={{ mt: 2 }} variant="contained" onClick={handleVerifyAcra}>
              Click here for more details in ACRA
            </Button>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CompanyDetails;
