export const CircleCheckListIcon = ({ color = '#FF6600', ...props }) => {
  return (
    <svg
      color={color}
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 9.00012L8.16666 10.6668L11.5 7.33345M5.52916 2.91429C6.12692 2.86658 6.6944 2.63154 7.15083 2.24262C7.66659 1.80284 8.3222 1.56128 9 1.56128C9.6778 1.56128 10.3334 1.80284 10.8492 2.24262C11.3056 2.63154 11.8731 2.86658 12.4708 2.91429C13.1465 2.96804 13.7808 3.26081 14.2601 3.74006C14.7393 4.21931 15.0321 4.85366 15.0858 5.52929C15.1335 6.12704 15.3686 6.69452 15.7575 7.15095C16.1973 7.66671 16.4388 8.32232 16.4388 9.00012C16.4388 9.67792 16.1973 10.3335 15.7575 10.8493C15.3686 11.3057 15.1335 11.8732 15.0858 12.471C15.0321 13.1466 14.7393 13.7809 14.2601 14.2602C13.7808 14.7394 13.1465 15.0322 12.4708 15.086C11.8731 15.1337 11.3056 15.3687 10.8492 15.7576C10.3334 16.1974 9.6778 16.439 9 16.439C8.3222 16.439 7.66659 16.1974 7.15083 15.7576C6.6944 15.3687 6.12692 15.1337 5.52916 15.086C4.85354 15.0322 4.21918 14.7394 3.73993 14.2602C3.26068 13.7809 2.96792 13.1466 2.91416 12.471C2.86646 11.8732 2.63142 11.3057 2.2425 10.8493C1.80272 10.3335 1.56116 9.67792 1.56116 9.00012C1.56116 8.32232 1.80272 7.66671 2.2425 7.15095C2.63142 6.69452 2.86646 6.12704 2.91416 5.52929C2.96792 4.85366 3.26068 4.21931 3.73993 3.74006C4.21918 3.26081 4.85354 2.96804 5.52916 2.91429Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
