import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Root, classes, Sidebar } from './SideMenu.styles';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Grid } from '@mui/material';
import helpIcon from '../../assets/icons/help.svg';
import homeIcon from '../../assets/icons/home.svg';
import LanguageSelector from '../LanguageSelector';
import {useLocation} from 'react-router-dom';

const SideMenu = () => {
  const [state, setState] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const location = useLocation();
  const isBuilderApp = location.search.replace(/(.*)=/,"") === 'builder';
  const handleBackSite = () => {
    isBuilderApp ? window.location.replace('https://app.dedoco.com') : window.location.replace('https://dedoco.com');
  }

  const openHelp = () => {
    window.open('https://www.dedoco.com/faq', '_blank', 'noopener');
  };

  return (
    <Root>
      <>
        <Button onClick={toggleDrawer(true)}>
          <MenuIcon />
        </Button>
        <Sidebar anchor={'right'} open={state} onClose={toggleDrawer(false)}>
          <Grid
            container
            flexDirection="column"
            role="presentation"
            className={classes.content}
            justifyContent="space-between"
          >
            <Grid container item alignItems="center" justifyContent="space-between">
              <Grid item>
                <LanguageSelector color="dark" />
              </Grid>
              <Grid item>
                <CloseIcon className={classes.closeIcon} onClick={toggleDrawer(false)} />
              </Grid>
            </Grid>
            <Grid container item flexDirection="column" className={classes.groupButton}>
              <Grid item>
                <Button
                  className={classes.homeButton}
                  onClick={handleBackSite}
                  fullWidth
                  startIcon={<img className={classes.helpIcon} src={homeIcon} alt="home" />}
                >
                  Return to Dedoco Homepage
                </Button>
                <Button
                  className={classes.getHelpButton}
                  onClick={openHelp}
                  fullWidth
                  startIcon={<img className={classes.helpIcon} src={helpIcon} alt="help" />}
                >
                  Get Help
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Sidebar>
      </>
    </Root>
  );
};

export default SideMenu;
