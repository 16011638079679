import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import Layout from '../../layout';
import { dedocoTheme } from '../../themes/dedocoTheme';
import UploadFile from '../../components/UploadFile';
import { classes, WrapperCardStyled, Root } from './DedocoVerify.styles';
import Card from '../../components/Card';
import { cardList } from '../../constants/Card';
import { Typography } from '@mui/material';
import { useState } from 'react';
import InvalidDocument from '../../components/Model/InvalidDocument';
import MultipleDocumentSelector from '../../components/Model/MultipleDocumentSelector';

const DedocoVerify = () => {
  const theme = dedocoTheme;
  const [docNotFound, setDocNotFound] = useState(false);
  const [multiDocFound, setMultiDocFound] = useState(false);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Layout>
          <Root>
            <Typography variant="headline_large" className={classes.title}>
              Verify your Dedoco Document
            </Typography>
            <Typography variant="body_large" className={classes.description}>
              Get instant verification from the blockchain
            </Typography>

            <div className={classes.divider}></div>
            <UploadFile setDocNotFound={setDocNotFound} setMultiDocFound={setMultiDocFound} />
            <div className={classes.divider}></div>

            <Typography variant="headline_medium" className={classes.description}>
              Why verify with Dedoco?
            </Typography>

            <WrapperCardStyled>{cardList.map(Card)}</WrapperCardStyled>
          </Root>
        </Layout>
        <InvalidDocument openModal={docNotFound} onClose={setDocNotFound} />
        <MultipleDocumentSelector openModal={multiDocFound} onClose={setMultiDocFound} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default DedocoVerify;
