import { sha3HashRegex } from '../../utils/regex';
import { dCertApi, dedocoApi } from './axios';

export const verifyWithDocHash = (docHash) => {
  if (!docHash.startsWith('0x')) docHash = `0x${docHash}`;
  if (!sha3HashRegex.test(docHash)) return Promise.reject('Invalid hash format.');

  return new Promise((resolve, reject) => {
    dedocoApi
      .get(`/verify/public/${docHash}`, {
        params: { include_dform: process.env.REACT_APP_DFORM_OFF ? null : true },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log(e);
        reject();
      });
  });
};

export const getCertificate = (certId) => {
  return new Promise((resolve, reject) => {
    dCertApi
      .get(`/certificate/${certId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
};

export const getCertificateWithDocument = (document) => {
  return new Promise((resolve, reject) => {
    dCertApi
      .post(`/certificate/document`, { document: JSON.parse(document) })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const uploadCertificate = (document) => {
  return new Promise((resolve, reject) => {
    dedocoApi
      .post('/opencerts', {
        certificate: {
          certificate: document.certificate,
          certificateName: document.certificateName,
          certificateImage: document.certificateImage,
          recipientName: document.recipientName,
          issuerName: document.issuerName,
          templateName: document.templateName,
          poweredby: document.poweredby,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
};

export const uploadImage = (_Id, CertificateImage) => {
  return new Promise((resolve, reject) => {
    dedocoApi
      .put('/opencerts/uploadImage', {
        certificate: {
          _id: _Id,
          certificateImage: CertificateImage,
        },
      })
      .then((res) => {
        if (res) {
          resolve(true);
        }
      })
      .catch((e) => {
        if (
          e.response.data &&
          e.response.data.error_description === 'Certificate Image already exists.'
        ) {
          resolve(true);
        } else {
          reject(e.response.data);
        }
      });
  });
};
