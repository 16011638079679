import DedocoVerifyIcon from '../assets/icons/dedoco-verify.svg';
import DedocoSecurityIcon from '../assets/icons/dedoco-security.svg';
import DedocoCertificationIcon from '../assets/icons/dedoco-certification.svg';

export const cardList = [
  {
    icon: DedocoVerifyIcon,
    title: 'Verify your project status',
    description: 'Real-time verification allows you to keep track of your project progress.',
  },
  {
    icon: DedocoSecurityIcon,
    title: 'The key lies in your document',
    description: 'Only documents registered with Dedoco can be verified.',
  },
  {
    icon: DedocoCertificationIcon,
    title: 'Receive a sharing certificate',
    description: 'View and download a copy of the signing certificate for safe keeping.',
  }
]
