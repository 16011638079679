export const LinkedinIcon = ({ color = '#0A66C2', ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.6328 13.6331H11.2621V9.92033C11.2621 9.03499 11.2463 7.89528 10.0291 7.89528C8.79431 7.89528 8.6054 8.8599 8.6054 9.85587V13.6328H6.23468V5.99792H8.51056V7.04131H8.54242C8.77018 6.65187 9.09931 6.3315 9.49473 6.11432C9.89016 5.89713 10.3371 5.79128 10.7879 5.80801C13.1907 5.80801 13.6338 7.38853 13.6338 9.44469L13.6328 13.6331ZM3.55975 4.95428C3.28765 4.95433 3.02165 4.87369 2.79538 4.72256C2.56911 4.57142 2.39275 4.35658 2.28858 4.10521C2.18441 3.85383 2.15711 3.57721 2.21014 3.31033C2.26318 3.04344 2.39417 2.79827 2.58653 2.60583C2.7789 2.41339 3.02401 2.28231 3.29087 2.22918C3.55773 2.17605 3.83435 2.20324 4.08575 2.30733C4.33715 2.41141 4.55205 2.58771 4.70326 2.81393C4.85447 3.04015 4.9352 3.30614 4.93525 3.57824C4.93529 3.75891 4.89973 3.93782 4.83062 4.10475C4.76151 4.27168 4.66021 4.42337 4.53249 4.55115C4.40476 4.67892 4.2531 4.78029 4.0862 4.84946C3.9193 4.91863 3.74042 4.95425 3.55975 4.95428ZM4.7451 13.6331H2.37193V5.99792H4.7451V13.6331ZM14.8147 0.00107926H1.18066C0.871207 -0.00241304 0.573012 0.117098 0.351627 0.333351C0.130241 0.549603 0.00377671 0.844905 0 1.15436V14.8454C0.00364745 15.155 0.130038 15.4505 0.351416 15.667C0.572793 15.8834 0.871053 16.0032 1.18066 15.9999H14.8147C15.1249 16.0038 15.424 15.8844 15.6462 15.6679C15.8685 15.4514 15.9957 15.1556 16 14.8454V1.15338C15.9956 0.843303 15.8683 0.547666 15.646 0.331419C15.4237 0.115171 15.1247 -0.00301024 14.8147 0.00107926Z"
        fill="currentColor"
      />
    </svg>
  );
};
