import { styled } from '@mui/material';

const PREFIX = 'DedocoLayout';

export const classes = {
  navbar: `${PREFIX}-navbar`,
  navbarIcons: `${PREFIX}-navbar-icons`,
  main: `${PREFIX}-main`,
  opencertsMain: `${PREFIX}-opencertsMain`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  opencertsContent: `${PREFIX}-opencertsContent`,
  logo: `${PREFIX}-logo`,
  iconWrapper: `${PREFIX}-iconWrapper`,
  signIcon: `${PREFIX}-signIcon`,
  helpIcon: `${PREFIX}-helpIcon`,
  innerIconWrapper: `${PREFIX}-innerIconWrapper`,
};

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.base,
  width: '100%',
  minHeight: '100vh',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: '100%',
  },

  [`& .${classes.navbarIcons}`]: {
    display: 'flex',
    gap: theme.spacing(6),
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(2),
      flexDirection: 'row',
    },
  },

  [`& .${classes.navbar}`]: {
    margin: theme.spacing(6),
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      margin: theme.spacing(5, 6),
      marginBottom: 0,
    },
  },

  [`& .${classes.main}`]: {
    boxSizing: 'border-box',
    margin: theme.spacing(6),
    flex: 1,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 4, 4, 4),
    },
  },

  [`& .${classes.opencertsMain}`]: {
    boxSizing: 'border-box',
    margin: theme.spacing(6),
    flex: 1,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.main,
    border: `1px solid ${theme.palette.stroke.main}`,
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 4, 4, 4),
    },
  },

  [`& .${classes.innerIconWrapper}`]: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    borderRadius: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    boxShadow: '0px 0px 2px 4px rgba(255, 255, 255, 0.04)',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  [`& .${classes.logo}`]: {
    width: theme.spacing(11),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(8),
    },
  },

  [`& .${classes.iconWrapper}`]: {
    width: theme.spacing(11),
    height: theme.spacing(11),
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    borderRadius: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },

  [`& .${classes.signIcon}`]: {
    width: theme.spacing(6),
    filter: 'invert(0)',
  },

  [`& .${classes.helpIcon}`]: {
    width: theme.spacing(4),
  },

  [`& .${classes.header}`]: {
    height: theme.spacing(16),
    padding: theme.spacing(3, 4),
    boxSizing: 'border-box',
    backgroundColor: theme.palette.stroke.main,
    border: `1px solid ${theme.palette.stroke.main}`,
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  [`& .${classes.content}`]: {
    height: `calc(100% - ${theme.spacing(24)})`,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: theme.spacing(4),
    },
  },

  [`& .${classes.opencertsContent}`]: {
    height: 'auto',
    boxSizing: 'border-box',

    border: `1px solid ${theme.palette.stroke.main}`,
    borderRadius: '8px',
    overflow: 'auto',

    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: theme.spacing(4),
    },
  },
}));
