import { styled } from '@mui/material';

const PREFIX = 'DedocoVerify';

export const classes = {
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  divider: `${PREFIX}-divider`,
};

export const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),

  [theme.breakpoints.down('sm')]: {
    padding: '0'
  },

  [`& .${classes.title}`]: {
    display: 'block',
    textAlign: 'left',
  },

  [`& .${classes.description}`]: {
    display: 'block',
    textAlign: 'left',
    marginTop: theme.spacing(2),
  },

  [`& .${classes.divider}`]: {
    margin: '24px 0',
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.stroke.main,
  },
}));

export const WrapperCardStyled = styled('div')(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    boxSizing: 'border-box',
  }
}));
