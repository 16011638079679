import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import VerifyCertification from '../../components/VerifyCertification';
import VerifyInfoCard from '../../components/VerifyInfoCard';
import Layout from '../../layout';
import { dedocoTheme } from '../../themes/dedocoTheme';
import { BoxDesktopStyled, BoxStyled, Root, TabStyled } from './DedocoDocument.styles';

const DedocoDocument = () => {
  const theme = dedocoTheme;
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Layout>
          <Root>
            <BoxStyled>
              <TabContext value={value}>
                <TabList value={value} onChange={handleChange} aria-label="tabs">
                  <TabStyled label="Certificate" value="1" />
                  <TabStyled label="Document" value="2" />
                </TabList>
                <TabPanel value="1" index={0}>
                  <VerifyCertification />
                </TabPanel>
                <TabPanel value="2" index={1}>
                  <VerifyInfoCard />
                </TabPanel>
              </TabContext>
            </BoxStyled>

            <BoxDesktopStyled>
              <VerifyCertification />
              <VerifyInfoCard />
            </BoxDesktopStyled>
          </Root>
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default DedocoDocument;
