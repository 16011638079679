import { styled } from '@mui/material';

const PREFIX = 'Header';

export const classes = {
  button: `${PREFIX}-button`,
  buttonText: `${PREFIX}-buttonText`,
  anotherDocument: `${PREFIX}-anotherDocument`
};

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [`& .${classes.button}`]: {
    color: theme.palette.text.primary,

    [`& .${classes.buttonText}`]: {
      marginLeft: theme.spacing(3),
    },
  },

  [`& .${classes.anotherDocument}`]: {
    color: theme.palette.text.main,
  },
}));
