export const HomeIcon = ({ color = '#FFFFFF', ...props }) => {
  return (
    <svg
      width={16}
      color={color}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 6.00016L8 1.3335L14 6.00016V13.3335C14 13.6871 13.8595 14.0263 13.6095 14.2763C13.3594 14.5264 13.0203 14.6668 12.6667 14.6668H3.33333C2.97971 14.6668 2.64057 14.5264 2.39052 14.2763C2.14048 14.0263 2 13.6871 2 13.3335V6.00016Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 14.6667V8H10V14.6667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
