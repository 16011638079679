import { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { process_env } from '../../utils/envData';

import { getCertificate, getCertificateWithDocument } from '../../lib/api/verify';

import { isValid, verify } from '@govtechsg/oa-verify';

import { opencertsGetData } from './utils/utils';

import { Cancel, ExpandMore } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { Box, Button, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { dedocoTheme } from '../../themes/dedocoTheme';
import { isoToStringWithTimezone } from '../../utils/dateParser';
import { Navbar } from '../Header';
import { ArrowBackIcon } from '../icons/ArrowBackIcon';
import { CircleCheckListIcon } from '../icons/CircleCheckListIcon';
import { LinkedinIcon } from '../icons/LinkedinIcon';
import { PrintIcon } from '../icons/PrintIcon';
import { VerifiedIcon } from '../icons/VerifiedIcon';
import { DCertSidebar } from '../Sidebar';
import './Opencert.css';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import ClipboardJS from 'clipboard';
import Layout from '../../layout';
import { CopyLinkIcon } from '../icons/CopyLinkIcon';
import CompanyDetails from './CompanyDetails';

const Opencert = () => {
  const certId = useParams();
  const location = useLocation();
  const history = useHistory();

  const certRef = useRef();

  const [document, setDocument] = useState();

  const [gettingDocument, setGettingDocument] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [err, setErr] = useState();

  const [rawDocument, setRawDocument] = useState();

  const [allState, setAllState] = useState(false);
  const [documentStatus, setDocumentStatus] = useState(false);
  const [documentIntegrity, setDocumentIntegrity] = useState(false);
  const [issuerIdentity, setIssuerIdentity] = useState(false);

  const [verified, setVerified] = useState(false);
  const [certificateId, setCertificateId] = useState('');
  const [certificateStatus, setCertificateStatus] = useState('');

  const [isAcra, setIsAcra] = useState(false);
  const [acraData, setAcraData] = useState(null);
  const [isBusinessProfileExpanded, setIsBusinessProfileExpanded] = useState(true);

  const [objectDocument, setObjectDocument] = useState();

  const [collapsePreview, setCollapsePreview] = useState(true);

  const [isSharingLoading, setIsSharingLoading] = useState(false);
  const isMobile = useMediaQuery(dedocoTheme.breakpoints.down('md'));

  const [copyShareLinkText, setCopyShareLinkText] = useState('Copy Share Link');

  useEffect(() => {
    if (location.state === undefined && certId.certificateId === undefined) {
      history.push('/');
    } else {
      if (location.state) {
        setDocument(location.state.document);
      }
      setGettingDocument(true);
    }
  }, []);

  useEffect(() => {
    const getDocument = async () => {
      if (!document) {
        await getCertificate(certId.certificateId)
          .then((results) => {
            setDocument(results.certificate);
            setCertificateStatus(results.status);

            setVerifying(true);
          })
          .catch((e) => {
            setErr(e);
            setIsLoading(false);
          });
      } else {
        setVerifying(true);
      }
    };

    if (gettingDocument) {
      getDocument();
    }
  }, [gettingDocument]);

  useEffect(() => {
    const verifyDoc = async (document) => {
      setIsLoading(true);

      const cleanData = opencertsGetData(JSON.parse(document));

      if (cleanData?.poweredby !== 'Powered by Dedoco') {
        // ACRA Certificate
        if (cleanData?.issuers[0]?.name === 'TRUSTBAR') {
          setRawDocument(document);
          setIsAcra(true);
          setAcraData(cleanData);

          verify(JSON.parse(document))
            .then((results) => {
              setAllState(isValid(results));
              setDocumentStatus(isValid(results, ['DOCUMENT_STATUS']));
              setDocumentIntegrity(isValid(results, ['DOCUMENT_INTEGRITY']));
              setIssuerIdentity(isValid(results, ['ISSUER_IDENTITY']));

              setIsLoading(false);
            })
            .catch((e) => {
              setErr(e);
              setIsLoading(false);
            });
        } else {
          setErr({ error: 'Error', error_description: 'Certificate not powered by Dedoco' });
          setIsLoading(false);
        }
      } else if (cleanData?.uen && cleanData?.companyType && cleanData?.entityName) {
        setIsAcra(true);
        setAcraData(cleanData);
        setRawDocument(document);
        verify(JSON.parse(document))
          .then((results) => {
            setAllState(isValid(results));
            setDocumentStatus(isValid(results, ['DOCUMENT_STATUS']));
            setDocumentIntegrity(isValid(results, ['DOCUMENT_INTEGRITY']));
            setIssuerIdentity(isValid(results, ['ISSUER_IDENTITY']));

            setIsLoading(false);
          })
          .catch((e) => {
            setErr(e);
            setIsLoading(false);
          });
      } else {
        setRawDocument(document);

        verify(JSON.parse(document))
          .then((results) => {
            setAllState(isValid(results));
            setDocumentStatus(isValid(results, ['DOCUMENT_STATUS']));
            setDocumentIntegrity(isValid(results, ['DOCUMENT_INTEGRITY']));
            setIssuerIdentity(isValid(results, ['ISSUER_IDENTITY']));

            setIsLoading(false);
          })
          .catch((e) => {
            setErr(e);
            setIsLoading(false);
          });
      }
    };

    if (verifying && document) {
      setObjectDocument(JSON.parse(document));

      verifyDoc(document);
    }
  }, [verifying, document]);

  useEffect(() => {
    if (allState) {
      setVerified(true);
    }
  }, [isLoading]);

  useEffect(() => {
    const getCertWithDoc = async (document) => {
      await getCertificateWithDocument(document)
        .then((result) => {
          setCertificateId(result._id);
        })
        .catch((error) => {
          setErr({ error: 'Error', error_description: `Certificate not found - ${error}` });
        });
    };

    if ((verified && !isAcra) || acraData?.poweredby === 'Powered by Dedoco') {
      getCertWithDoc(document);
    } else if (verified) {
      setCertificateId(certId.certificateId);
    }
  }, [acraData?.poweredby, certId.certificateId, document, isAcra, verified]);

  const printHandler = useReactToPrint({
    content: () => certRef.current,
  });

  const copyShareLinkHandler = () => {
    const url = `${process_env('REACT_APP_BASE_URL')}/opencert/${certificateId}`;

    const clipboard = new ClipboardJS('.copyShareLink', {
      text: function () {
        return url;
      },
    });

    clipboard.on('success', function (e) {
      setCopyShareLinkText('Share Link Copied!');
      setTimeout(() => {
        setCopyShareLinkText('Copy Share Link');
        e.clearSelection();
        clipboard.destroy();
      }, 5000);
    });
  };

  const shareHandler = async () => {
    setIsSharingLoading(true);

    let url = `${process_env('REACT_APP_BASE_URL')}/opencert/${certificateId}`;

    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);

    setIsSharingLoading(false);
  };

  const backToHome = () => {
    history.push('/');
  };

  const backToDedocoHome = () => {
    window.location.replace('https://dedoco.com');
  };

  const toggleCollapseCertificateHandler = () => {
    setCollapsePreview((prevState) => !prevState);
  };

  const toggleBusinessProfileExpansion = () => {
    setIsBusinessProfileExpanded((prevState) => !prevState);
  };

  const splitHashStringHandler = (string) => string?.split(':string:')?.[1];

  const onBack = () => history.push('../');

  const theme = dedocoTheme;

  const getTitle = () => {
    if (!isLoading) {
      if (err) {
        return err.error_description;
      } else {
        if (!allState) {
          return 'The uploaded certificate is not verified';
        } else if (isAcra) {
          return acraData?.entityName;
        } else {
          return splitHashStringHandler(objectDocument?.data?.certificate);
        }
      }
    } else {
      return '';
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Layout opencerts>
          <Fragment>
            {!isMobile && (
              <Navbar>
                <Stack flexDirection="row" justifyContent="space-between" width="100%">
                  <Button onClick={backToDedocoHome}>
                    <ArrowBackIcon color={dedocoTheme.palette.background.base} />
                    <Typography
                      variant="label_medium"
                      ml={2}
                      color={dedocoTheme.palette.background.base}
                    >
                      Return to Dedoco Homepage
                    </Typography>
                  </Button>
                  <Box width="max-content" display="flex" gap={'1rem'}>
                    <Button onClick={onBack}>
                      <Typography
                        variant="label_medium"
                        color={dedocoTheme.palette.primary.main}
                        onClick={backToHome}
                      >
                        Verify another document
                      </Typography>
                    </Button>

                    {certificateId && !isSharingLoading && (
                      <>
                        <Button
                          className="copyShareLink"
                          onClick={copyShareLinkHandler}
                          variant="outlined"
                          endIcon={<CopyLinkIcon color={dedocoTheme.palette.darkGrey.main} />}
                        >
                          <Typography
                            variant="label_medium"
                            color={dedocoTheme.palette.primary.main}
                          >
                            {copyShareLinkText}
                          </Typography>
                        </Button>

                        <Button
                          onClick={shareHandler}
                          disabled={isSharingLoading}
                          variant="outlined"
                          endIcon={<LinkedinIcon />}
                        >
                          <Typography
                            variant="label_medium"
                            color={dedocoTheme.palette.primary.main}
                          >
                            {isSharingLoading ? 'Loading...' : 'Share to LinkedIn'}
                          </Typography>
                        </Button>

                        <Button
                          onClick={printHandler}
                          variant="outlined"
                          endIcon={<PrintIcon color={dedocoTheme.palette.darkGrey.main} />}
                        >
                          <Typography
                            variant="label_medium"
                            color={dedocoTheme.palette.primary.main}
                          >
                            Print
                          </Typography>
                        </Button>
                      </>
                    )}
                  </Box>
                </Stack>
              </Navbar>
            )}

            {isMobile && (
              <DCertSidebar>
                {certificateId && !isSharingLoading && (
                  <>
                    <Button
                      className="copyShareLink"
                      onClick={copyShareLinkHandler}
                      startIcon={<CopyLinkIcon />}
                      variant="label_large"
                      sx={{
                        mb: 3,
                        width: '100%',
                        justifyContent: 'flex-start',
                        height: dedocoTheme.spacing(11),
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        borderRadius: dedocoTheme.spacing(2),
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        },
                      }}
                    >
                      {copyShareLinkText}
                    </Button>

                    <Button
                      onClick={shareHandler}
                      disabled={isSharingLoading}
                      startIcon={<LinkedinIcon />}
                      variant="label_large"
                      sx={{
                        mb: 3,
                        width: '100%',
                        justifyContent: 'flex-start',
                        height: dedocoTheme.spacing(11),
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        borderRadius: dedocoTheme.spacing(2),
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        },
                      }}
                    >
                      {isSharingLoading ? 'Loading...' : 'Share to LinkedIn'}
                    </Button>

                    <Button
                      onClick={printHandler}
                      startIcon={<PrintIcon />}
                      variant="label_large"
                      sx={{
                        width: '100%',
                        justifyContent: 'flex-start',
                        height: dedocoTheme.spacing(11),
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        borderRadius: dedocoTheme.spacing(2),
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        },
                      }}
                    >
                      Print
                    </Button>
                  </>
                )}
              </DCertSidebar>
            )}

            <Stack
              p={'2rem 0 2rem 2rem'}
              flexDirection="row"
              sx={{
                backgroundColor: dedocoTheme.palette.background.main,
                gap: '3rem',
                width: '100%',
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                [dedocoTheme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  gap: 'unset',
                  p: '1.5rem',
                },
              }}
            >
              <Stack
                width={{
                  width: `calc(100% - 20rem)`,
                  height: '100vh',
                }}
                sx={{
                  [dedocoTheme.breakpoints.down('md')]: {
                    width: '100%',
                    height: 'auto',
                  },
                }}
              >
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
                  <Typography
                    variant="headline_large"
                    display="block"
                    textAlign="left"
                    pr={3}
                    sx={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {getTitle()}
                  </Typography>
                  {!isLoading ? (
                    allState && certificateStatus !== 'revoked' && !err ? (
                      <VerifiedIcon
                        color={dedocoTheme.palette.success.main}
                        width={!isMobile ? '2rem' : null}
                      />
                    ) : (
                      <Cancel sx={{ color: 'red', fontSize: !isMobile ? '2.5rem' : '80px' }} />
                    )
                  ) : (
                    <></>
                  )}
                </Box>
                <Stack bgcolor={dedocoTheme.palette.supporting.extraLight} p={3} borderRadius={3}>
                  <Typography variant="title_medium" display="block" mb={5} textAlign="left">
                    We certify that this document:
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="body_medium">Has not been tampered with</Typography>
                    {!isLoading ? (
                      documentIntegrity ? (
                        <CircleCheckListIcon color={dedocoTheme.palette.success.main} />
                      ) : (
                        <Cancel sx={{ color: 'red', fontSize: !isMobile ? '1.125rem' : '18px' }} />
                      )
                    ) : (
                      <span className="status-text">-</span>
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="body_medium">Has been issued</Typography>
                    {!isLoading ? (
                      documentStatus ? (
                        <CircleCheckListIcon color={dedocoTheme.palette.success.main} />
                      ) : (
                        <Cancel sx={{ color: 'red', fontSize: !isMobile ? '1.125rem' : '18px' }} />
                      )
                    ) : (
                      <span className="status-text">-</span>
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="body_medium">
                      Was issued by a registered institution
                    </Typography>
                    {!isLoading ? (
                      issuerIdentity ? (
                        <CircleCheckListIcon color={dedocoTheme.palette.success.main} />
                      ) : (
                        <Cancel sx={{ color: 'red', fontSize: !isMobile ? '1.125rem' : '18px' }} />
                      )
                    ) : (
                      <span className="status-text">-</span>
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="body_medium">Has never been revoked or expired</Typography>
                    {!isLoading ? (
                      allState && certificateStatus !== 'revoked' ? (
                        <CircleCheckListIcon color={dedocoTheme.palette.success.main} />
                      ) : (
                        <Cancel sx={{ color: 'red', fontSize: !isMobile ? '1.125rem' : '18px' }} />
                      )
                    ) : (
                      <span className="status-text">-</span>
                    )}
                  </Box>
                </Stack>
                {allState === true && !err?.error_description && (
                  <Box textAlign="left" borderRadius={3}>
                    <Accordion
                      expanded={collapsePreview}
                      onClick={toggleCollapseCertificateHandler}
                      sx={{ my: 4, background: 'transparent', boxShadow: 'none' }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="title_large">Certificate Preview</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box p={4} bgcolor={dedocoTheme.palette.background.paper} width={'60%'}>
                          {verified && rawDocument && certificateId && (
                            <img
                              ref={certRef}
                              className="certImage"
                              src={`${process_env(
                                'REACT_APP_OPENCERT_S3_BASE'
                              )}/${certificateId}.png`}
                              alt={`${certificateId}.png`}
                            />
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    {isAcra && (
                      <Accordion
                        expanded={isBusinessProfileExpanded}
                        onClick={toggleBusinessProfileExpansion}
                        sx={{ my: 4, background: 'transparent', boxShadow: 'none' }}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="title_large">Business Profile</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CompanyDetails companyData={acraData} />
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Box>
                )}
              </Stack>
              <Stack
                p={5}
                bgcolor={dedocoTheme.palette.background.paper}
                textAlign="left"
                height="100%"
                width="19.375rem"
                position="relative"
                boxSizing="border-box"
                top={'-2rem'}
                sx={{
                  [dedocoTheme.breakpoints.down('md')]: {
                    width: '100%',
                    position: 'unset',
                    top: 'unset',
                  },
                }}
              >
                <Typography variant="title_medium" mb={3}>
                  About the Certificate
                </Typography>
                <Divider />
                <Box my={3}>
                  <Typography
                    variant="label_medium"
                    mb={3}
                    display="block"
                    color={dedocoTheme.palette.text.secondary}
                  >
                    Name
                  </Typography>
                  <Typography variant="body_medium">{getTitle()}</Typography>
                </Box>
                <Divider />
                <Box my={3}>
                  <Typography
                    variant="label_medium"
                    mb={3}
                    display="block"
                    color={dedocoTheme.palette.text.secondary}
                  >
                    Status
                  </Typography>
                  <Typography variant="body_medium">
                    {isLoading ? 'Verifying...' : allState ? 'Verified' : 'Not verified'}
                  </Typography>
                </Box>
                <Divider />
                <Box my={3}>
                  <Typography
                    variant="label_medium"
                    mb={3}
                    display="block"
                    color={dedocoTheme.palette.text.secondary}
                  >
                    Issuer
                  </Typography>
                  <Typography variant="body_medium">
                    {allState && splitHashStringHandler(objectDocument?.data?.issuers?.[0]?.name)}
                  </Typography>
                </Box>
                <Divider />
                <Box my={3}>
                  <Typography
                    variant="label_medium"
                    mb={3}
                    display="block"
                    color={dedocoTheme.palette.text.secondary}
                  >
                    Domain
                  </Typography>
                  <Typography variant="body_medium">
                    {allState &&
                      splitHashStringHandler(
                        objectDocument?.data?.issuers?.[0]?.identityProof?.location
                      )?.toUpperCase()}
                  </Typography>
                </Box>
                <Divider />
                <Box my={3}>
                  <Typography
                    variant="label_medium"
                    mb={3}
                    display="block"
                    color={dedocoTheme.palette.text.secondary}
                  >
                    Creation Date
                  </Typography>
                  <Typography variant="body_medium">
                    {allState && isoToStringWithTimezone(objectDocument?.proof?.[0]?.created)}
                  </Typography>
                </Box>
                <Divider />
                {/* <Box my={3}>
            <Typography
              variant="label_medium"
              mb={3}
              display="block"
              color={dedocoTheme.palette.text.secondary}
            >
              Certificate Store
            </Typography>
            <Typography variant="body_medium">
              0x6c806e3F0Pe013eC7E8b7A7fa62eFdP80d039404
            </Typography>
          </Box>
          <Divider /> */}
                <Box my={3}>
                  <Typography
                    variant="label_medium"
                    mb={3}
                    display="block"
                    color={dedocoTheme.palette.text.secondary}
                  >
                    Certificate Root
                  </Typography>
                  <Typography variant="body_medium">
                    {allState && objectDocument?.signature?.merkleRoot}
                  </Typography>
                </Box>
                <Divider />
                <Box my={3}>
                  <Typography
                    variant="label_medium"
                    mb={3}
                    display="block"
                    color={dedocoTheme.palette.text.secondary}
                  >
                    Certificate Hash
                  </Typography>
                  <Typography variant="body_medium">
                    {allState && objectDocument?.signature?.targetHash}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Fragment>
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Opencert;
